import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  RightActiveClass=false;

  SignIn(){this.RightActiveClass=false;}
  SignUp(){this.RightActiveClass=true;}



// =========== mobile View =============
RegisterActiveClass=false;
ShowContainer=true;
Login(){
  this.RegisterActiveClass=false;
  this.ShowContainer=true;
}
Register(){
  this.RegisterActiveClass=true;
  this.ShowContainer=false;
}


}
