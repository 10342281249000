<div class="container">
    <div class="d-flex p-0 px-xxl-5 pb-xl-5 m-0 row">
        <!-- ====================================== Side bar User Menu ============================== -->
        <div class="d-none d-xl-block col-xl-3">
            <div class="position-sticky" style="top: 7.4rem;">

                <app-user-profile-card></app-user-profile-card>

                <div class="card card-body user-menus-section">
                    <div>
                        <app-user-menu></app-user-menu>
                    </div>
                </div>
            </div>
        </div>

        <!-- ====================================== Main content Section ============================== -->
        <div class="col-xl-9 mt-4">
            <div class="mt-0 ms-xl-4 mt-xl-1">
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>