import { Component , Input} from '@angular/core';

@Component({
  selector: 'app-product-section',
  templateUrl: './product-section.component.html',
  styleUrls: ['./product-section.component.scss']
})
export class ProductSectionComponent {
  @Input() sections='New Section';
product_images = [ 
  {name:'Opulent Embroidered Lehenga Choli' , img:'https://www.mangaldeep.co.in/image/cache/data/charming-peach-reception-lehenga-choli-61486-800x1100.jpg'},
  {name:'blue silk floor length suit' , img:'https://www.mangaldeep.co.in/image/cache/data/blue-color-art-silk-floor-length-suit-37867-800x1100.jpg'},
  {name:'purple silk mens kurta jacket' , img:'https://www.mangaldeep.co.in/image/cache/data/purple-color-art-silk-mens-jacket-set-43728-800x1100.jpg'},
  {name:'banarasi silk festival saree' , img:'https://www.mangaldeep.co.in/image/cache/data/prodigious-banarasi-silk-festival-classic-saree-54393-0-800x1100.jpg'},
  {name:'black silk Anarkali Suits' , img:'https://www.mangaldeep.co.in/image/cache/data/black-color-art-silk-anarkali-dress-38387-800x1100.jpg'},
  {name:'patola Kids Lehenga Choli ' , img:'https://www.mangaldeep.co.in/image/cache/data/purple-patola-delight-kids-lehenga-choli-set-63800-800x1100.JPG'},
]
}
