import { Component } from '@angular/core';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent {



  // CartImgs = [
  //   { OrderId:10254854455, name: 'Trainers Shoes', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_34_a_1-450x600.jpg', RealPrice: '1982', SellingPrice: '1726', Color: '#7c818d', status:3,OrderDate:'25 Sep, 2023 ' },
  //   { OrderId:14554254854, name: 'Tinted Sunglasses', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_26_a_2-450x600.jpg', RealPrice: '1199', SellingPrice: '782', Color: '#687666', status:1,OrderDate:'22 Sep, 2023 ' },
  //   { OrderId:19845645955, name: 'Leather Belt', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_10_1-600x600.jpg', RealPrice: '1285', SellingPrice: '1198', Color: '#854838', status:3,OrderDate:'22 Sep, 2023 ' },
  //   { OrderId:18554546687, name: 'Boxy Denim Jacket', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_36_c_1-600x600.jpg', RealPrice: '2155', SellingPrice: '1989', Color: '#57718c', status:2, OrderDate:'21 Sep, 2023 ' },
  CartImgs = [
    { OrderId:10254854455, name: 'Peter', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_34_a_1-450x600.jpg', PayMode: 'COD', SellingPrice: '1726', Color: '#7c818d', status:3,OrderDate:'25 Sep, 2023 ' },
    { OrderId:14554254854, name: 'John', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_26_a_2-450x600.jpg', PayMode: 'PAID', SellingPrice: '782', Color: '#687666', status:1,OrderDate:'22 Sep, 2023 ' },
    { OrderId:19845645955, name: 'Sam', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_10_1-600x600.jpg', PayMode: 'PAID', SellingPrice: '1198', Color: '#854838', status:3,OrderDate:'22 Sep, 2023 ' },
    { OrderId:18554546687, name: 'Vivek', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_36_c_1-600x600.jpg', PayMode: 'PAID', SellingPrice: '1989', Color: '#57718c', status:2, OrderDate:'21 Sep, 2023 ' },
  ];
  OrderImgs = [
    { OrderId:10254854455, name: 'Trainers Shoes', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_34_a_1-450x600.jpg', RealPrice: '1982', SellingPrice: '1726', Color: '#7c818d', status:3 ,OrderDate:'25 Sep, 2023 ' }
  ];

  
  ShowHideOldPassword = false
  ShowHideNewPassword = false
  ShowHideCnfPassword = false
  EyeOldShowHide(){
    this.ShowHideOldPassword = !this.ShowHideOldPassword
  }
  EyeNewShowHide(){
    this.ShowHideNewPassword = !this.ShowHideNewPassword
  }
  EyeCnfShowHide(){
    this.ShowHideCnfPassword = !this.ShowHideCnfPassword
  }
  

  OrderDeatails(){
    console.log('hello')
  }
}
