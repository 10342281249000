<!-- footer start-->
<div class="container-fluid">
  <div class="container border-bottom">
    <div class="pt-4 pb-2 row col-12 m-0 d-flex justify-content-between">
      <div class="footer-about py-2 col-12 col-md-6 col-lg-3">
        <h5>About Us</h5>
        <div class="d-flex">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="currentColor"
              class="bi bi-geo-alt me-1" viewBox="0 0 20 17">
              <path
                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
          </div>
          <div>A bulu venture c/o bulu,
            3rd floor above R Studio. M.
            G. Road, Valsad -396001, Gujarat, India</div>
        </div>

        <div class="d-flex mt-2"><span class=" me-2  d-flex justify-content-center align-items-center"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope"
              viewBox="0 0 16 16">
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
            </svg></span>socialethnicstar&#64;gmail.com</div>
        <div class="d-flex mt-2"><span class=" me-2  d-flex justify-content-center align-items-center"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone"
              viewBox="0 0 16 16">
              <path
                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg></span>(+91) 82006-23309</div>
        <div class="d-flex mt-2"><span class=" me-2  d-flex justify-content-center align-items-center"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp"
              viewBox="0 0 16 16">
              <path
                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg></span>(+91) 82006-23309</div>
      </div>

      <div class="footer-pages py-2 col-12 col-md-6 col-lg-3">
        <h5>Privacy policy</h5>
        <div>
          <ul>
            <li>Terms & conditions</li>
            <li>Shipping policy</li>
            <li>Return policy</li>
          </ul>
        </div>
      </div>
      <div class="footer-Help py-2 col-12 col-md-6 col-lg-3">
        <h5>Help</h5>
        <div>
          <ul>
            <li>FAQ</li>
            <li>Contact Us</li>
            <li>Store locator</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-3 col-6 ">
      
  </div> -->
    <!-- <div class="row mb-3">
      <h4>Suiting</h4>
      <div>Suiting fabric can be used for a wide range of garments including pants, blazers, skirts and dresses. Suiting
        is a woven fabric that can be made with a wide variety of contents including wool, cotton, rayon, silk and
        polyester. Lycra or Spandex can be mixed with the other fabric to make the suiting stretch. This feature allows
        for more comfort and ease of wearing.Suiting can be found in so many different weaves including herringbone,
        houndstooth, plainweave, twill, tricotine, crepe, gabardine, tweed, boucle, pinstripe and more.</div>
    </div>
    <div class="row mb-3">
      <h4>Shirting</h4>
      <div>Shirting fabric is a kind of primarily cotton or cotton blend cloth used to make shirts. These fabrics should
        be breathable, durable, pleasant to the touch, and comfortable to wear all year round. That is why high thread
        count and yarn dyed structure are the essential features of shirting fabrics

        The popularity of shirts all around the globe has contributed greatly to the diversity of the fabrics they are
        made of: types of weave, fibers selection, design and pattern motifs. The variety of shirting fabrics is
        captivating. You can choose pure cotton, linen or man-made fibers, opt for finer or thicker options, go for
        vibrant or pastel shades, or try stripes, checks and florals. Shirting fabrics are used both for formal and
        casual wear.</div>
    </div>
    <div class="row mb-4">
      <h4>Ethnic wear</h4>
      <div>The word ethnic is related to the cultural ties, the traditions and something also sacred to that specific
        land. Today we see the word ethic being connected to almost anything and everything. There is ethnic food,
        ethnic wear, ethnic themes, or ethnic music etc.

        When we are talking about ethnic designs, what I mean is the designs or rather the prints on the fabrics. This
        is one class of textile design which is connected to the roots of the culture and tradition of people and
        definitely has geographical indications (GI).</div>
    </div> -->
  </div>

  <div class="row mt-3">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright {{ today | date:'y'}} © Designed and devoloped by Murchcode Technologies </p>
    </div>
  </div>
</div>