import { Component } from '@angular/core';
import { log } from 'console';

@Component({
  selector: 'app-featured-one',
  templateUrl: './featured-one.component.html',
  styleUrls: ['./featured-one.component.scss']
})
export class FeaturedOneComponent {
  slideConfig = {   // Start configration of slick slider -------- ====
    'slidesToShow': 3,"autoplay": true, "touch-move": false,
    responsive: [  // Set responsive of slick slider in configration as a array -------- ====
      {  // Create responsive object-1
        breakpoint: 767,
        settings: {
          'slidesToShow': 1,
          'slidesToScroll': 1
        },

      },  // End responsive object-1
    ]  // responsive array finish
  };
}
