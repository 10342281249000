import { Component, OnInit } from '@angular/core';
import { SharedVariableService } from '../../services/shared.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  SelectAllValue = true;
  ClearAllValue = false;
  
  loopcount = Array(20).fill(0);
  filterActivated;
  filterItems = [
    {
      name: 'Category',
      active: false,
      subItem: [
        { name: 'Tops', active: false },
        { name: 'Jeans & Tousers', active: false },
        { name: 'Knitwear & Sweats', active: false },
        { name: 'Shorts', active: false },
        { name: 'Outerwear', active: false },
        { name: 'Suits & Tailoring', active: false },
        { name: 'All in Ones', active: false },
      ]
    },
    {
      name: 'Product Type',
      active: false,
      subItem: [
        { name: 'T-shirts', active: false },
        { name: 'Shirts', active: false },
        { name: 'Tousers', active: false },
        { name: 'Jeans', active: false },
        { name: 'Socks', active: false },
        { name: 'Hoodies', active: false },
        { name: 'Sweatshirts', active: false },
        { name: 'Jackets', active: false },
      ]
    },
    {
      name: 'Style',
      active: false,
      subItem: [
        { name: 'Straight Leg', active: false },
        { name: 'tapered', active: false },
        { name: 'Crew', active: false },
        { name: 'Wide Leg', active: false },
        { name: 'Sports Shorts', active: false },
        { name: 'Denim', active: false },
        { name: 'Oxford', active: false },
        { name: 'Longline', active: false },
      ]
    },
    {
      name: 'Brand',
      active: false,
      subItem: [
        { name: '47 Brands', active: false },
        { name: 'Calvin Klein', active: false },
        { name: 'Collusion', active: false },
        { name: 'Dickies', active: false },
        { name: 'Ellesse', active: false },
        { name: 'Fred Perry', active: false },
        { name: 'G-Star', active: false },
        { name: 'Guess Originals', active: false },
      ]
    },
    {
      name: 'Colour',
      active: false,
      subItem: [
        { name: 'Black', active: false },
        { name: 'White', active: false },
        { name: 'Blue', active: false },
        { name: 'Grey', active: false },
        { name: 'Navy', active: false },
        { name: 'Red', active: false },
        { name: 'Yellow', active: false },
        { name: 'Multi', active: false },

      ]
    },
    {
      name: 'Length',
      active: false,
      subItem: [
        { name: 'Length', active: false },

      ]
    },
    {
      name: 'Body Fit',
      active: false,
      subItem: [
        { name: 'Main Collection', active: false },
        { name: 'Plus Size', active: false },
        { name: 'Tall', active: false },

      ]
    },
    {
      name: 'Size',
      active: false,
      subItem: [
        { name: 'S', active: false },
        { name: 'M', active: false },
        { name: 'L', active: false },
        { name: 'XL', active: false },
        { name: 'XXL', active: false },
        { name: '2XL', active: false },
        { name: '3XL', active: false },

      ]
    },
    {
      name: 'Prize Range',
      active: false,
      subItem: [
        { name: 'Prize Range', active: false },

      ]
    },
  ];


  ApplyFilter() {
    for (let item = 0; item < this.filterItems.length; item++) {
      const element = this.filterItems[item];
      // let Arr = element.name.replace(/\s/g, "")
      let Arr = element.subItem
      for (let A = 0; A < Arr.length; A++) {
        if (Arr[A].active == true) {
          // console.log(Arr[A].name);
        }
      }
    }
    this.sharedService.OffFilter = false;
  }
  UnCheckAllSelect() {
    for (let item = 0; item < this.filterItems.length; item++) {
      const element = this.filterItems[item];
      // let Arr = element.name.replace(/\s/g, "")
      let Arr = element.subItem
      for (let A = 0; A < Arr.length; A++) {
        Arr[A].active = false;
      }
    }
  }


  activated(Aname) {
    for (let item = 0; item < this.filterItems.length; item++) {
      const element = this.filterItems[item];
      if (element.name == Aname) {
        element.active = true;

        let Arr = element.subItem
        for(let j=0; j< Arr.length; j++){
          // if(Arr[j].active == true){
          //   this.SelectAllValue = true;
          //   this.ClearAllValue = false;
          // }
        }

      } else {
        element.active = false;
      }
    }
  }


  SelectAll() {
    for (let item = 0; item < this.filterItems.length; item++) {
      const element = this.filterItems[item];
      if (element.active == true) {
        let Arr = element.subItem
        this.SelectAllValue = false;
        this.ClearAllValue = true;
        for (let A = 0; A < Arr.length; A++) {
          Arr[A].active = true
        }
      }
    }
  }
  ClearAll(){
    for (let item = 0; item < this.filterItems.length; item++) {
      const element = this.filterItems[item];
      if (element.active == true) {
        this.SelectAllValue = true;
        this.ClearAllValue = false;
        let Arr = element.subItem
        for (let A = 0; A < Arr.length; A++) {
          Arr[A].active = false
        }
      }
    }
  }




  FilterTitle;
  SubCategoryValue = false;
  SubCategoryShow(name) {
    this.SubCategoryValue = true;
    this.FilterTitle = name;
    this.activated(name)
  }
  filterShow() {
    this.SubCategoryValue = false;
  }

  SubCatSelect(f, a) {
    this.filterItems[f].subItem[a].active = !this.filterItems[f].subItem[a].active
  }

  FilterName = 'Filter';
  constructor(public sharedService: SharedVariableService) { }
  offCanvas() {
    this.sharedService.OffFilter = false;
  }

}
