<div class="position-relative">
    <section><app-banner-home></app-banner-home></section>
    <!-- ======= product sections start ======= -->
    <section class="paddin-top-4rem"><app-collection-card></app-collection-card></section>
    <section class="paddin-Y-3rem" ><app-occasion-product></app-occasion-product></section>
    <section class="paddin-Y-3rem"><app-single-product></app-single-product></section>
    <section class="paddin-Y-3rem"><app-new-arrival></app-new-arrival></section>
    <section class="paddin-Y-3rem"><app-category-banner></app-category-banner></section>
    <section class="paddin-Y-3rem"><app-social-banner></app-social-banner></section>
    <!-- ======= product sections end ======= -->
    <section><app-featured-one></app-featured-one></section>
    <section><app-news-letter></app-news-letter></section>
</div>