import { Routes } from '@angular/router';
import { AppointmentFormComponent } from 'src/app/pages/appointment-form/appointment-form.component';
import { AuthComponent } from 'src/app/pages/auth/auth.component';
import { MyCartComponent } from 'src/app/pages/cart/cart.component';
import { CheckOutComponent } from 'src/app/pages/check-out/check-out.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { OrderConfirmComponent } from 'src/app/pages/order-confirm/order-confirm.component';
import { PaymentComponent } from 'src/app/pages/payment/payment.component';
import { ProductDetailWithFilterComponent } from 'src/app/pages/product-detail-with-filter/product-detail-with-filter.component';
import { ProductDetailsComponent } from 'src/app/pages/product-details/product-details.component';
import { ProductListingComponent } from 'src/app/pages/product-listing/product-listing.component';
import { ChangePasswordComponent } from 'src/app/pages/user-account/change-password/change-password.component';
import { MyOrdersComponent } from 'src/app/pages/user-account/my-orders/my-orders.component';
import { OrderDetailsComponent } from 'src/app/pages/user-account/my-orders/order-details/order-details.component';
import { OrderListingComponent } from 'src/app/pages/user-account/my-orders/order-listing/order-listing.component';
import { MyProfileComponent } from 'src/app/pages/user-account/my-profile/my-profile.component';
import { ShippingAddComponent } from 'src/app/pages/user-account/shipping/add/add.component';
import { ShippingEditComponent } from 'src/app/pages/user-account/shipping/edit/edit.component';
import { ShippingListComponent } from 'src/app/pages/user-account/shipping/list/list.component';
import { ShippingComponent } from 'src/app/pages/user-account/shipping/shipping.component';
import { UserAccountComponent } from 'src/app/pages/user-account/user-account.component';
import { WishListComponent } from 'src/app/pages/wish-list/wish-list.component';

export const full: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'product-listing', component: ProductListingComponent },
  { path: 'product-details', component: ProductDetailsComponent },
  { path: 'product-details-filter', component: ProductDetailWithFilterComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'check-out', component: CheckOutComponent },
  { path: 'wish-list', component: WishListComponent },
  { path: 'my-cart', component: MyCartComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'appointment-form', component: AppointmentFormComponent },
  { path: 'order-confirm', component: OrderConfirmComponent },
  {
    path: 'my-account', component: UserAccountComponent,
    children: [
      { path: '', component: MyProfileComponent, pathMatch: 'full' },
      {
        path: 'my-orders', component: MyOrdersComponent,
        children: [
          { path: '', component: OrderListingComponent, pathMatch: 'full' },
          { path: 'order-details', component: OrderDetailsComponent, pathMatch: 'full' }
        ]
      },
      {
        path: 'shipping', component: ShippingComponent,
        children: [
          { path: '', component: ShippingListComponent ,pathMatch: 'full'},
          { path: 'add', component: ShippingAddComponent ,},
          { path: 'edit/:id', component: ShippingEditComponent ,},
        ]
      },
      { path: 'change-password', component: ChangePasswordComponent },
    ]
  },
  // {
  //   path: 'error-page',
  //   loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule),
  // },
  // {
  //   path: 'authentication',
  //   loadChildren: () => import('../../pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  // },
  // {
  //   path: 'coming-soon',
  //   loadChildren: () => import('../../pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  // },
  // {
  //   path: 'maintenance',
  //   loadChildren: () => import('../../pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  // }
];
