import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-feather-icons',
  templateUrl: './feather-icons.component.html',
  styleUrls: ['./feather-icons.component.scss']
})
export class FeatherIconsComponent implements OnInit {

  @Input('icon') public icon;

  constructor(@Inject(PLATFORM_ID)private platformId: any) { }

  ngOnInit() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        feather.replace();
      }
    });
  }

}
