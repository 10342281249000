import { Component } from '@angular/core';
import { SharedVariableService } from '../../services/shared.service'

@Component({
  selector: 'app-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss']
})
export class OffCanvasComponent {

  FilterName='Filter';
  constructor(public sharedService: SharedVariableService) { }
  offCanvas(){
    this.sharedService.OffFilter = false;
  }
}
