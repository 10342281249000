<div class="d-flex justify-content-start mt-5">
    <div class="status-bar position-relative">
        <div class="status-box h-100">
            <div class="d-flex flex-column h-100 px-1 justify-content-between">
                <div class="status-cicle-1">
                     <div class="status-title">Order Confirmed
                        <div class="date"><small>23-09-2023</small></div>
                     </div>
                     
                </div>
                <div class="status-cicle-2">
                     <div class="status-title">Shipped
                        <div class="date"><small>25-09-2023</small></div>
                     </div>
                </div>
                <div class="status-cicle-3">
                     <div class="status-title">Out For Delivery</div>
                </div>
                <div class="status-cicle-4">
                     <div class="status-title">Delivered</div>
                </div>
            </div>
        </div>
        <div class="status-bar-fill"></div>
    </div>
</div>
