<div class="" (click)="toggleNotificationMobile()">
  <!-- <div class="notification-box" (click)="toggleNotificationMobile()"> -->
  <!-- <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg> -->
  <div class="currancy-logo">
    <!-- <img src="assets/images/currency/dollar-currency-symbol.png" alt="" height="14px"> -->
    <img [src]="CurracyImage" alt="" height="15px">
  </div>
  <!-- <span class="badge rounded-pill badge-secondary">4</span> -->
</div>

<!-- dropdown list -->
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Currency</h6>
  <ul>
    @for (item of currency; track item.id) {
    <li class="border-4" (click)="CurrencySelect(item.name)">
      <!-- <p> -->
        <span>
          <div class="currancy-logo">
            <img src="{{ item.img }}" alt="{{item.name}}" height="12px">
          </div>
        </span>
        <strong [ngClass]="item.active ? '' : 'currency-clr' ">{{item.name}}</strong>
      <!-- </p> -->
    </li>
    }
    <!-- *ngFor="let item of currency; index as i -->
  </ul>
</div>