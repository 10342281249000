<div class="">
    <app-breadcrumb [title]="'Wish-List'" [active_item]="'Wish-List'"></app-breadcrumb>
    <!-- <app-bread-crumbs [BreadCrumb]="'Home  Product-listing'"></app-bread-crumbs> -->
</div>
<div class="col-12 col-md-12 px-md-4 product-view-section container-md-fluid container-xl p-0 ">
    <div class="row justify-content-center px-md-3 py-md-2">
        <div class="col-12 col-xl-10  pb-3 ">
            <div class="px-3 px-md-0 row">
                <div class="wishlist col-12 col-md-6 m-0 col-xl-12 row mb-3 py-1 px-0 pb-4 pb-xl-1 d-flex  justify-content-between align-items-center"
                    *ngFor="let wish of wishImgs">
                    <div class="col-12 col-xl d-flex align-items-center">
                        <div class="col d-flex my-3 px-1">
                            <div class="col-4 col-xl-2 px-xl-2">
                                <div class="wish-image ratio" [ngStyle]="{'background-image':'url('+ wish.img +')'}">
                                </div>
                            </div>
                            <div class="ps-2 col d-flex flex-column justify-content-center">
                                <div class="mb-2 wish-product-title"><strong>{{ wish.description }}</strong></div>
                                <div class="mb-2 wish-product-price d-flex align-items-center"> <small
                                        class="me-2 d-flex align-items-center"> <s> &#8377; {{ wish.mrp }}</s>
                                    </small> &#8377; {{ wish.price }} </div>
                                <div class="mb-2">
                                    <div class="wish-produxt-color-box">
                                        <div class="wish-produxt-color" [ngStyle]="{'background-color': wish.Color}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 d-flex d-xl-block align-items-center px-3">
                        <div class="col wish-add-to-cart mb-xl-3">
                            <app-normal-btn [btnText]="'Add to Cart'" [btnColor]="'#018849'"></app-normal-btn>
                        </div>
                        <div class="col wish-delete d-flex justify-content-center align-items-center">
                            <div class=" ms-2 mx-xl-0 del-btn">
                                <app-del-btn></app-del-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>