import { Component } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ShippingListComponent {
  shippingAddress = [
    {
      firstName: 'Jeevan',
      lastName: 'Singh',
      mobileNo: '9856422545',
      email: 'jeevansingh@gmail.com',
      addressLine1: '38F Block C, Sharan Nagar',
      addressLine2: '',
      townCity: 'Pali',
      state: 'Rajasthan',
      pincode: '306401',
      country: 'India',
      default: true,
    },
    {
      firstName: 'Indra',
      lastName: 'Singh',
      mobileNo: '9856422545',
      email: 'jeevansingh@gmail.com',
      addressLine1: '556 Nehru Colony,Near Mahadev Mandir',
      addressLine2: '',
      townCity: 'Pali',
      state: 'Rajasthan',
      pincode: '306401',
      country: 'India',
      default: false,
    },
  ]
  defaultAddress(i) {
    this.shippingAddress.forEach(el => el.default = false)
    this.shippingAddress[i].default = true;
  }
  deleteAddress(i) {
    ((this.shippingAddress[i].default == true) && (this.shippingAddress.length > 1)) ?
      ((this.shippingAddress.splice(i, 1)), this.shippingAddress[0].default = true)
      : (this.shippingAddress.length > 1 ? this.shippingAddress.splice(i, 1) : '');
  }
}
