import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkout-steps',
  templateUrl: './checkout-steps.component.html',
  styleUrls: ['./checkout-steps.component.scss']
})
export class CheckoutStepsComponent {
@Input() HeaderName="Header";
@Input() Step;
}
