import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { SharedVariableService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent {

  constructor(private sharedService: SharedVariableService, @Inject(PLATFORM_ID)private platformId: any) { }

  ProductColors1: string = 'black'
  ProductColors2: string = '#fff'
  ProductColors3: string = 'green'
  ProductColors4: string = 'pink'
  ProductColors5: string = 'yellow'

  image = [
    {
      sale: 'SALE',
      name: 'Designer Black Jacket Style Indowestern In Silk',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/50761_1.jpg?crop=center&height=1100&v=1699854707&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/50761_3.jpg?crop=center&height=1100&v=1699854707&width=800",
      price: '13999',
      mrp: '14599'
    },
    {
      sale: '',
      name: 'chic zari green contemporary sttyle saree',
      wish: true,
      img1: "https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51226.jpg?crop=center&height=1100&v=1701683853&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51226-b.jpg?crop=center&height=1100&v=1701683853&width=800",
      price: '8995',
      mrp: ''
    },
    {
      sale: '',
      name: 'regal pink silk lehenga choli',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/50757_1.jpg?crop=center&height=1100&v=1699853974&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/50757_3.jpg?crop=center&height=1100&v=1699853973&width=800",
      price: '18495',
      mrp: ''
    },
    {
      sale: 'SALE',
      name: 'amazing green lehenga choli',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51222.jpg?crop=center&height=1100&v=1701679559&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51222-b.jpg?crop=center&height=1100&v=1701679559&width=800",
      price: '7595',
      mrp: '9294'
    },
    {
      sale: '',
      name: 'Festive Wear Golden Kurta Pajama In Silk',
      wish: true,
      img1: "https://ethnicstar.com/cdn/shop/files/51582_1.jpg?crop=center&height=1100&v=1701233709&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/51582_2.jpg?crop=center&height=1100&v=1701233708&width=800",
      price: '6199',
      mrp: ''
    },
    {
      sale: '',
      name: 'hypotizing cotton silk ceremonial lehenga choli',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/50473_1.jpg?crop=center&height=1100&v=1697886957&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/50473_2.jpg?crop=center&height=1100&v=1697886957&width=800",
      price: '20160',
      mrp: ''
    },
    {
      sale: 'SALE',
      name: 'Onion Pink Indowestern In Art Silk Fabric',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/52039_1.jpg?crop=center&height=1100&v=1701862307&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/52039_3.jpg?crop=center&height=1100&v=1701862307&width=800",
      price: '18899',
      mrp: '19759'
    },
    {
      sale: 'SALE',
      name: 'Designer Light Pink Jacket Style Indowestern In Silk',
      wish: false,
      img1: "https://ethnicstar.com/cdn/shop/files/50762_1.jpg?crop=center&height=1100&v=1699854879&width=800",
      img2: "https://ethnicstar.com/cdn/shop/files/50762_3.jpg?crop=center&height=1100&v=1699854879&width=800",
      price: '13995',
      mrp: '14598'
    },
  ]


  ImageColCLass = '';
  ImageDetailsColCLass = '';
  ColCLass = 'col-3';
  ActiveClass = 4
  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      
      if (window.innerWidth > 769) {
        this.ColCLass = "col-3"
      } else {
        this.ColCLass = "col-6"
      }
      window.addEventListener('resize', this.onResize.bind(this));

    }
  }

  
  onResize(event: any) {
    if (window.innerWidth > 821) {
      this.ColCLass = "col-3"
    } else {
      this.ColCLass = "col-6"
    }
  }
  nav_gap: boolean;


  clickCol12() {
    this.ColCLass = 'col-12 d-block d-md-flex'
    this.ImageColCLass = 'col-12 col-md-3'
    this.ImageDetailsColCLass = 'ps-md-4 pt-2 pt-md-0 col-12 col-md-4 d-flex flex-column justify-content-around'
    this.ActiveClass = 1
  }
  clickCol6() {
    this.ColCLass = 'col-6'
    this.ImageColCLass = ''
    this.ImageDetailsColCLass = ''
    this.ActiveClass = 2
  }
  clickCol4() {
    this.ColCLass = 'col-4'
    this.ImageColCLass = ''
    this.ImageDetailsColCLass = ''
    this.ActiveClass = 3
  }
  clickCol3() {
    this.ColCLass = 'col-3'
    this.ImageColCLass = ''
    this.ImageDetailsColCLass = ''
    this.ActiveClass = 4
  }
  loopcount = Array(20).fill(0);
  FilterCount = Array(12).fill(4);



  offCanvas() {
    // this.sharedService.OffFilter = true
    this.sharedService.OffFilter = !this.sharedService.OffFilter
  }

}
