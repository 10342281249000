import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-normal-btn',
  templateUrl: './normal-btn.component.html',
  styleUrls: ['./normal-btn.component.scss']
})
export class NormalBtnComponent {
 @Input() btnText='Add to Cart'
 @Input() btnColor="#777"
}
