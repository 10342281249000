<div class="container py-3">
    <div class="text-center mb-5">
        <h6 class="text-danger">{{ 'Festive sale' | uppercase }}</h6>
        <h4>{{ sections | uppercase }}</h4>
    </div>
    <div class="d-flex row">
        <div class="col-12 col-md-6 col-lg" *ngFor="let image of product_images">
            <app-product-card [images1]="image.img"></app-product-card>
            <div class="product-details">
                <div><h6 class="text-center">{{ image.name | uppercase }}</h6></div>
                <div class="d-flex justify-content-center">
                    <div class="product-rate text-danger">&#8377; 1,250</div>
                    <div class="ms-2 mrp-price"><s> &#8377; 1,500</s></div>
                  </div>
            </div>
        </div>
    </div>
</div>