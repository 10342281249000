import { Component } from '@angular/core';

@Component({
  selector: 'app-occasion-product',
  templateUrl: './occasion-product.component.html',
  styleUrls: ['./occasion-product.component.scss']
})
export class OccasionProductComponent {
  occasion = [ 
    {wish:false,name:'Art Silk Groom Wear Sherwani For Men' , img:'https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrown40875-_2_-e.jpg?crop=center&height=1100&v=1676460303&width=800',price:'20995'},
    {wish:false,name:'Imported Jodhpuri Suit For Wedding' , img:'https://ethnicstar.com/cdn/shop/files/45556_1.jpg?crop=center&height=1100&v=1685619125&width=800',price:'5995'},
    {wish:false,name:'Shaded Color Sequins Embroidered Indowestern Set' , img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51248.jpg?crop=center&height=1100&v=1701758383&width=800',price:'8995'},
    {wish:false,name:'Groom Wear Designer Sherwani For Wedding' , img:'https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrown40873-_2.jpg?crop=center&height=1100&v=1676459739&width=800',price:'27499'},
    {wish:false,name:'Designer Light Pink Jacket Style Indowestern In Silk' , img:'https://ethnicstar.com/cdn/shop/files/50762_1.jpg?crop=center&height=1100&v=1699854879&width=800',price:'13995'},
    {wish:false,name:'Yellow And White Cotton Silk Kurta Pajama ' , img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown45172_dd2b915e-6f9d-44b0-b444-410f21d8199a.jpg?crop=center&height=1100&v=1685613928&width=800',price:'2995'},
  ]
}
