<div class="login-body d-flex  justify-content-center  align-items-center">
    <!-- <h2>Weekly Coding Challenge #1: Sign in/up Form</h2> -->
    <div class="container d-none d-lg-block" [ngClass]="RightActiveClass ? 'right-panel-active' : '' " id="container">
        <div class="form-container sign-up-container">
            <form action="#">
                <h2>Create Account</h2>
                <div class="social-container d-flex align-items-center">
                    <a href="#" class="social"> <img src="assets/images/social-app/google.png" alt="" height="20px"></a>
                    <a href="#" class="social"><img src="assets/images/social-app/facebook.png" alt=""
                            height="24px"></a>
                    <a href="#" class="social"><img src="assets/images/social-app/apple.png" alt="" height="20px"></a>
                </div>
                <span style="margin-bottom: 1rem;">or use your email for registration</span>
                <input type="text" placeholder="Name" />
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <div class="d-flex"><input type="checkbox" id="Subscribe" class="subscribe-checkbox"><label
                        class="mb-0 d-flex align-items-center ms-2" for="Subscribe">Subscribe</label></div>
                <button style="margin-top: 1rem;">Sign Up</button>
            </form>
        </div>
        <div class="form-container sign-in-container">
            <form action="#">
                <h2>Sign in</h2>
                <div class="social-container d-flex align-items-center">
                    <a href="#" class="social"> <img src="assets/images/social-app/google.png" alt="" height="20px"></a>
                    <a href="#" class="social"> <img src="assets/images/social-app/facebook.png" alt=""
                            height="24px"></a>
                    <a href="#" class="social"> <img src="assets/images/social-app/apple.png" alt="" height="20px"></a>
                </div>
                <span style="margin-bottom: 1rem;">or use your account</span>
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <a href="#">Forgot your password?</a>
                <a [routerLink]="['/my-account']">
                    <button style="margin-top: 2rem;">Sign In</button>
                </a>
            </form>
        </div>
        <div class="overlay-container">
            <div class="overlay">
                <div class="overlay-panel overlay-left">
                    <h1>Welcome Back!</h1>
                    <p>To keep connected with us please login with your personal info</p>
                    <button class="ghost" id="signIn" (click)="SignIn()">Sign In</button>
                </div>
                <div class="overlay-panel overlay-right">
                    <h1>Hello, Friend!</h1>
                    <p>Enter your personal details and start journey with us</p>
                    <button class="ghost" id="signUp " (click)="SignUp()">Sign Up</button>
                </div>
            </div>
        </div>
    </div>


    <div class="d-lg-none d-flex login-overflow align-items-center">
        <div class="mct-mobile-login">
            <div class="d-flex justify-content-center align-items-center  ">
                <div class="login-register-box d-flex mt-1">
                    <div class="login-register-toggle"
                        [ngClass]="RegisterActiveClass ? 'RegisterActiveClass' : 'RegisterInActiveClass'">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center ToggleBtns"
                        [ngClass]="RegisterActiveClass ? 'toggle-text-black' : 'toggle-text-white'" (click)="Login()">
                        SignIn</div>
                    <div class="col-6 d-flex justify-content-center align-items-center ToggleBtns"
                        [ngClass]="RegisterActiveClass ? 'toggle-text-white' : 'toggle-text-black'"
                        (click)="Register()">
                        SignUp</div>
                </div>
            </div>

            <div class="d-flex position-relative">
                <div class=" w-100 position-absolute" [ngClass]="ShowContainer ? 'sign-in-box-show':'sign-in-box-hide'">
                    <!-- ========== Sign in ============ -->
                    <div class="d-flex mt-3 flex-column justify-content-center align-items-center  ">
                        <h1 class="sign-text">Sign in</h1>
                        <div class="social-container d-flex align-items-center">
                            <a href="#" class="social"> <img src="assets/images/social-app/google.png" alt=""
                                    height="20px"></a>
                            <a href="#" class="social"><img src="assets/images/social-app/facebook.png" alt=""
                                    height="24px"></a>
                            <a href="#" class="social"><img src="assets/images/social-app/apple.png" alt=""
                                    height="20px"></a>
                        </div>
                        <div class="description-text">or use your account</div>
                    </div>


                    <div class="d-flex mt-3 flex-column justify-content-center align-items-center  ">
                        <div class="w-100 px-3"><input class="form-control sign-inputs" type="email" placeholder="Email"
                                aria-label="default input example"></div>
                        <div class="w-100 px-3"><input class="form-control sign-inputs" type="Password"
                                placeholder="Password" aria-label="default input example"></div>
                        <div class="mt-3">Forgot your password?</div>
                    </div>


                    <div class="d-flex justify-content-center align-items-center  ">
                        <button class=" mt-5 signin-button">Sign In</button>
                    </div>

                </div> <!-- ========== Sign in end ============ -->

                <div class=" w-100  position-absolute"
                    [ngClass]="ShowContainer ? 'sign-up-box-hide':'sign-up-box-show'">
                    <!-- ========== Sign up ============ -->
                    <div class="d-flex mt-3 flex-column justify-content-center align-items-center  ">
                        <h1 class="sign-text">Create Account</h1>
                        <div class="social-container d-flex align-items-center">
                            <a href="#" class="social"> <img src="assets/images/social-app/google.png" alt=""
                                    height="20px"></a>
                            <a href="#" class="social"><img src="assets/images/social-app/facebook.png" alt=""
                                    height="24px"></a>
                            <a href="#" class="social"><img src="assets/images/social-app/apple.png" alt=""
                                    height="20px"></a>
                        </div>
                        <div class="description-text">or use your email for registration</div>
                    </div>


                    <div class="d-flex mt-3 flex-column justify-content-center align-items-center  ">
                        <div class="w-100 px-3"><input class="form-control sign-inputs" type="text" placeholder="Name"
                                aria-label="default input example"></div>
                        <div class="w-100 px-3"><input class="form-control sign-inputs" type="email" placeholder="Email"
                                aria-label="default input example"></div>
                        <div class="w-100 px-3"><input class="form-control sign-inputs" type="Password"
                                placeholder="Password" aria-label="default input example"></div>
                        <div class="d-flex mt-2"><input type="checkbox" id="Subscribe" class="subscribe-checkbox"><label
                                class="mb-0 d-flex align-items-center ms-2" for="Subscribe">Subscribe</label></div>
                    </div>


                    <div class="d-flex justify-content-center align-items-center  ">
                        <button class=" mt-4 signin-button">Sign Up</button>
                    </div>

                </div> <!-- ========== Sign up end ============ -->
            </div>

        </div>
    </div>
</div>