import { Component } from '@angular/core';
import { SharedVariableService } from '../../services/shared.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  Ulshowhide = false;
  ShippingValue = false;
  constructor(public sharedService: SharedVariableService){}
  OrderHideOnAllBtn(){
    this.Ulshowhide = false;
  }
  ShippingFun(){ this.ShippingValue = true;}
  UlShowHide(){
    this.Ulshowhide = !this.Ulshowhide
    this.ShippingValue = false;
  }

  AccountClose(){
    this.sharedService.Menu = false;
  }
}
