<!-- Page Header Start-->

<div class="page-header">
  <div>
    <app-announcement-bar></app-announcement-bar>
  </div>
  <div class=" d-none d-lg-flex header-wrapper row m-0" style="border-bottom: 1px solid #eee;">
    <div class="nav-left col">
      <div class="d-flex">
        <a [routerLink]="['/my-account/my-orders']" class="top-nav-left-link">Track Order</a>
        <a [routerLink]="['/wish-list']" class="top-nav-left-link">My Wish List</a>
        <a [routerLink]="['/check-out']" class="top-nav-left-link">Checkout</a>
      </div>
    </div>
    <div class="nav-right col-3 col-lg-2 p-0 ms-auto order-2 order-lg-3">
      <ul class="nav-menus">
        <!-- <li class="search-box-button cart-nav" (click)="searchButton = !searchButton"> -->
        <li class="search-box-button cart-nav" (click)="this.searchToggle.searchToggle = !this.searchToggle.searchToggle">
          <label for="Search-box-input" class="m-0">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.8438 19.3203C21.0781 19.5286 21.0781 19.75 20.8438 19.9844L19.9844 20.8438C19.75 21.0781 19.5286 21.0781 19.3203 20.8438L14.5938 16.1172C14.4896 16.013 14.4375 15.9089 14.4375 15.8047V15.2578C12.901 16.5859 11.1302 17.25 9.125 17.25C6.88542 17.25 4.97135 16.4557 3.38281 14.8672C1.79427 13.2786 1 11.3646 1 9.125C1 6.88542 1.79427 4.97135 3.38281 3.38281C4.97135 1.79427 6.88542 1 9.125 1C11.3646 1 13.2786 1.79427 14.8672 3.38281C16.4557 4.97135 17.25 6.88542 17.25 9.125C17.25 11.1302 16.5859 12.901 15.2578 14.4375H15.8047C15.9349 14.4375 16.0391 14.4896 16.1172 14.5938L20.8438 19.3203ZM4.71094 13.5391C5.9349 14.763 7.40625 15.375 9.125 15.375C10.8438 15.375 12.3151 14.763 13.5391 13.5391C14.763 12.3151 15.375 10.8438 15.375 9.125C15.375 7.40625 14.763 5.9349 13.5391 4.71094C12.3151 3.48698 10.8438 2.875 9.125 2.875C7.40625 2.875 5.9349 3.48698 4.71094 4.71094C3.48698 5.9349 2.875 7.40625 2.875 9.125C2.875 10.8438 3.48698 12.3151 4.71094 13.5391Z"
                fill="#000000"></path>
            </svg>
          </label>
        </li>
        <li class="cart-nav d-none d-lg-block">
          <a [routerLink]="['/my-cart']">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.95 6H19.7V17.875C19.7 18.7344 19.3875 19.4635 18.7625 20.0625C18.1635 20.6875 17.4344 21 16.575 21H5.325C4.46563 21 3.72344 20.6875 3.09844 20.0625C2.49948 19.4635 2.2 18.7344 2.2 17.875V6H5.95C5.95 4.61979 6.43177 3.44792 7.39531 2.48438C8.3849 1.49479 9.56979 1 10.95 1C12.3302 1 13.5021 1.49479 14.4656 2.48438C15.4552 3.44792 15.95 4.61979 15.95 6ZM13.1375 3.8125C12.5385 3.1875 11.8094 2.875 10.95 2.875C10.0906 2.875 9.34844 3.1875 8.72344 3.8125C8.12448 4.41146 7.825 5.14062 7.825 6H14.075C14.075 5.14062 13.7625 4.41146 13.1375 3.8125ZM17.825 17.875V7.875H15.95V9.4375C15.95 9.69792 15.8589 9.91927 15.6766 10.1016C15.4943 10.2839 15.2729 10.375 15.0125 10.375C14.7521 10.375 14.5307 10.2839 14.3484 10.1016C14.1661 9.91927 14.075 9.69792 14.075 9.4375V7.875H7.825V9.4375C7.825 9.69792 7.73385 9.91927 7.55156 10.1016C7.36927 10.2839 7.14792 10.375 6.8875 10.375C6.62708 10.375 6.40573 10.2839 6.22344 10.1016C6.04115 9.91927 5.95 9.69792 5.95 9.4375V7.875H4.075V17.875C4.075 18.2135 4.19219 18.5 4.42656 18.7344C4.68698 18.9948 4.98646 19.125 5.325 19.125H16.575C16.9135 19.125 17.2 18.9948 17.4344 18.7344C17.6948 18.5 17.825 18.2135 17.825 17.875Z"
                fill="#000000"></path>
            </svg>
          </a>
        </li>
        <!-- <li class="onhover-dropdown">
          <a [routerLink]="['/wish-list']">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart"
              viewBox="0 0 16 16">
              <path
                d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
            </svg>
          </a>
        </li> -->
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="onhover-dropdown d-none d-lg-block">
          <!-- <a [routerLink]="['/my-account']"> -->
          <a [routerLink]="['/auth']">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.5156 12.875C15.9479 12.875 17.1719 13.3958 18.1875 14.4375C19.2292 15.4531 19.75 16.6771 19.75 18.1094V19.125C19.75 19.6458 19.5677 20.0885 19.2031 20.4531C18.8385 20.8177 18.3958 21 17.875 21H4.125C3.60417 21 3.16146 20.8177 2.79688 20.4531C2.43229 20.0885 2.25 19.6458 2.25 19.125V18.1094C2.25 16.6771 2.75781 15.4531 3.77344 14.4375C4.8151 13.3958 6.05208 12.875 7.48438 12.875C7.82292 12.875 8.31771 12.9792 8.96875 13.1875C9.64583 13.3958 10.3229 13.5 11 13.5C11.6771 13.5 12.3542 13.3958 13.0312 13.1875C13.7083 12.9792 14.2031 12.875 14.5156 12.875ZM17.875 19.125V18.1094C17.875 17.1979 17.5365 16.4167 16.8594 15.7656C16.2083 15.0885 15.4271 14.75 14.5156 14.75C14.4375 14.75 14.0208 14.8542 13.2656 15.0625C12.5365 15.2708 11.7812 15.375 11 15.375C10.2188 15.375 9.45052 15.2708 8.69531 15.0625C7.96615 14.8542 7.5625 14.75 7.48438 14.75C6.57292 14.75 5.77865 15.0885 5.10156 15.7656C4.45052 16.4167 4.125 17.1979 4.125 18.1094V19.125H17.875ZM14.9844 10.6094C13.8906 11.7031 12.5625 12.25 11 12.25C9.4375 12.25 8.10938 11.7031 7.01562 10.6094C5.92188 9.51562 5.375 8.1875 5.375 6.625C5.375 5.0625 5.92188 3.73438 7.01562 2.64062C8.10938 1.54688 9.4375 1 11 1C12.5625 1 13.8906 1.54688 14.9844 2.64062C16.0781 3.73438 16.625 5.0625 16.625 6.625C16.625 8.1875 16.0781 9.51562 14.9844 10.6094ZM13.6562 3.96875C12.9271 3.23958 12.0417 2.875 11 2.875C9.95833 2.875 9.07292 3.23958 8.34375 3.96875C7.61458 4.69792 7.25 5.58333 7.25 6.625C7.25 7.66667 7.61458 8.55208 8.34375 9.28125C9.07292 10.0104 9.95833 10.375 11 10.375C12.0417 10.375 12.9271 10.0104 13.6562 9.28125C14.3854 8.55208 14.75 7.66667 14.75 6.625C14.75 5.58333 14.3854 4.69792 13.6562 3.96875Z"
                fill="#000000"></path>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="header-wrapper row m-0">
    <div class="d-flex d-xl-none justify-content-start navbar-menu-button col-2 col-lg-auto me-2 p-0 order-0"
      (click)="MenuShow()">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.2812 5.84375H2.71875C2.58854 5.84375 2.47135 5.80469 2.36719 5.72656C2.28906 5.6224 2.25 5.50521 2.25 5.375V4.125C2.25 3.99479 2.28906 3.89062 2.36719 3.8125C2.47135 3.70833 2.58854 3.65625 2.71875 3.65625H19.2812C19.4115 3.65625 19.5156 3.70833 19.5938 3.8125C19.6979 3.89062 19.75 3.99479 19.75 4.125V5.375C19.75 5.50521 19.6979 5.6224 19.5938 5.72656C19.5156 5.80469 19.4115 5.84375 19.2812 5.84375ZM19.2812 12.0938H2.71875C2.58854 12.0938 2.47135 12.0547 2.36719 11.9766C2.28906 11.8724 2.25 11.7552 2.25 11.625V10.375C2.25 10.2448 2.28906 10.1406 2.36719 10.0625C2.47135 9.95833 2.58854 9.90625 2.71875 9.90625H19.2812C19.4115 9.90625 19.5156 9.95833 19.5938 10.0625C19.6979 10.1406 19.75 10.2448 19.75 10.375V11.625C19.75 11.7552 19.6979 11.8724 19.5938 11.9766C19.5156 12.0547 19.4115 12.0938 19.2812 12.0938ZM19.2812 18.3438H2.71875C2.58854 18.3438 2.47135 18.3047 2.36719 18.2266C2.28906 18.1224 2.25 18.0052 2.25 17.875V16.625C2.25 16.4948 2.28906 16.3906 2.36719 16.3125C2.47135 16.2083 2.58854 16.1562 2.71875 16.1562H19.2812C19.4115 16.1562 19.5156 16.2083 19.5938 16.3125C19.6979 16.3906 19.75 16.4948 19.75 16.625V17.875C19.75 18.0052 19.6979 18.1224 19.5938 18.2266C19.5156 18.3047 19.4115 18.3438 19.2812 18.3438Z"
          fill="black"></path>
      </svg>
    </div>
    <div class="col p-0 d-flex justify-content-lg-start justify-content-center order-1 position-static">
      <a [routerLink]="['/']" class=" main-logo d-md-flex d-none mb-0 align-items-center"><img
          src="/assets/images/logo/ethnic-star.png" title="Ehtnic Star"
          alt="Ehtnic Star" style="height: 36px;"></a>
      <a [routerLink]="['/']" class="main-logo d-block d-md-none mb-0"><img src="/assets/images/logo/ethnic-star.png"
          title="Ehtnic Star" alt="Ehtnic Star" style="height: 30px;"></a>
      <div class="d-none d-lg-flex col align-items-center justify-content-center">
        <ul class="d-flex justify-content-between col-10">
          <li *ngFor="let menu of Menus" class="menu-list" (mouseenter)="this.searchToggle.searchToggle = false">
            <div>
              <div class="d-flex" style="padding: 2px 0px;">
                <div class="menu-name me-2">{{ menu.name }}</div>
                <div class="menu-icon-down d-flex justify-content-center align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" stroke="black"
                    class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
              <div class="menu-hover-border"></div>
            </div>
            <div class="menu-list-box position-absolute col-12">
              <ul class=" col-12 subcategory">
                <li *ngFor="let childrens of menu.children" class="col-2 subchild-list-li">
                  <div>{{ childrens.name }}</div>
                  <ul class="subchild-list">
                    <li *ngFor="let subchild of childrens.children" class="d-flex">
                      <a routerLink="/product-listing">{{ subchild.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-right col-3 col-lg-2 p-0 ms-auto order-2 order-lg-3">
      <a routerLink="/appointment-form" class=" d-none d-lg-flex live-video-shopping">
        <button>Live Video Shopping</button>
      </a>
    </div>
  </div>
  <div>
    <app-search-box class="search-box-app" *ngIf="this.searchToggle.searchToggle">
      <div select=".Search-Close-Btn" (click)="this.searchToggle.searchToggle = false"
        class="d-flex justify-content-center align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x"
          viewBox="0 0 16 16">
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
    </app-search-box>
  </div>
</div>