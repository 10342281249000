<div class="col mct-product-card">
    <div class="card card-body">
        <!-- <div class="ratio product-card-image" [ngStyle]="{'background-image': 'url('+ images1 +')'}"  ></div> -->
        <div class=" product-card-image" [ngStyle]="{'background-image': 'url('+ images1 +')'}"  >
        </div>
        <div class="ratio position-absolute mct-overlay">
            <a class="ratio product-card-image-overlay" [ngStyle]="{'background-image': 'url('+ images2 +')'}"></a>
            <a routerLink="/product-details-filter" class="d-flex justify-content-center align-items-end"> 
                <app-main-button class="shop-btn" [MctBtnClr]="'#fff'" [MctBtnText]="'Add to Cart'"></app-main-button> 
            </a>
        </div>
        <div class="position-absolute top-0 w-100 d-flex p-2">
            <span class="badge badge-danger text-white float-start">SALE</span>
        </div>
        <div class="add-wish">
            <app-wish-icon [wish]="wish"></app-wish-icon>
        </div>
    </div>
</div>
