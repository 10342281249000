import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { ChatService } from "./services/chat.service";
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { TableService } from "./services/table.service";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from './components/header/elements/swiper/swiper.component';
import { AnnouncementBarComponent } from './components/header/elements/announcement-bar/announcement-bar.component';
import { NavigatinBarComponent } from './components/header/elements/navigatin-bar/navigatin-bar.component';
import { BannerHomeComponent } from './components/carousal/banner-home/banner-home.component';
import { MainButtonComponent } from './components/buttons/main-button/main-button.component';
import { SearchBoxComponent } from './components/header/elements/search-box/search-box.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { FilterComponent } from './components/filter/filter.component';
import { OffCanvasComponent } from './components/off-canvas/off-canvas.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { DelBtnComponent } from './components/del-btn/del-btn.component';
import { NormalBtnComponent } from './components/normal-btn/normal-btn.component';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { OrderDetailBtnComponent } from './components/order-detail-btn/order-detail-btn.component';
import { OrderStatusTimelineComponent } from './components/order-status-timeline/order-status-timeline.component';
import { OrderTimelineMobileComponent } from './components/order-timeline-mobile/order-timeline-mobile.component';
import { SingleProductComponent } from './components/single-product/single-product.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CategoryBannerComponent } from './components/category-banner/category-banner.component';
import { SocialBannerComponent } from './components/social-banner/social-banner.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { OccasionProductComponent } from './components/occasion-product/occasion-product.component';
import { NewArrivalComponent } from './components/new-arrival/new-arrival.component';
import { WishIconComponent } from './components/wish-icon/wish-icon.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { SearchBtnComponent } from './components/search-btn/search-btn.component';
import {BasicProductCardComponent} from './components/basic-product-card/basic-product-card.component';
import { CheckoutStepsComponent } from './components/checkout-steps/checkout-steps.component';
import { CheckoutBtnComponent } from './components/checkout-btn/checkout-btn.component'

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    SvgIconComponent,
    SwiperComponent,
    AnnouncementBarComponent,
    NavigatinBarComponent,
    BannerHomeComponent,
    MainButtonComponent,
    SearchBoxComponent,
    FooterMenuComponent,
    BreadCrumbsComponent,
    FilterComponent,
    OffCanvasComponent,
    MobileMenuComponent,
    HomeMenuComponent,
    DelBtnComponent,
    NormalBtnComponent,
    UserProfileCardComponent,
    UserMenuComponent,
    OrderDetailBtnComponent,
    OrderStatusTimelineComponent,
    OrderTimelineMobileComponent,
    SingleProductComponent,
    CollectionCardComponent,
    CategoryBannerComponent,
    SocialBannerComponent,
    OccasionProductComponent,
    NewArrivalComponent,
    WishIconComponent,
    VideoCallComponent,
    SearchBtnComponent,
    BasicProductCardComponent,
    CheckoutStepsComponent,
    CheckoutBtnComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // DragulaModule.forRoot(),
    TranslateModule.forRoot(),
    CarouselModule,
    SwiperModule,
    SlickCarouselModule],
    providers: [NavService, ChatService, LayoutService, TableService, DecimalPipe],
    exports: [
      NgbModule, 
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      LoaderComponent,
      BreadcrumbComponent,
      FeatherIconsComponent,
      TapToTopComponent,
      DisableKeyPressDirective,
      OnlyAlphabetsDirective,
      OnlyNumbersDirective,
      NgbdSortableHeader,
      SvgIconComponent,
      SwiperModule,
      BannerHomeComponent,
    MainButtonComponent,
    SearchBoxComponent,
    BreadCrumbsComponent,
    FilterComponent,
    OffCanvasComponent,
    MobileMenuComponent,
    HomeMenuComponent,
    DelBtnComponent,
    NormalBtnComponent,
    UserProfileCardComponent,
    UserMenuComponent,
    OrderDetailBtnComponent,
    OrderStatusTimelineComponent,
    OrderTimelineMobileComponent,
    SingleProductComponent,
    CategoryBannerComponent,
    CollectionCardComponent,
    SocialBannerComponent,
    OccasionProductComponent,
    NewArrivalComponent,
    WishIconComponent,
    VideoCallComponent,
    SearchBtnComponent,
    BasicProductCardComponent,
    CheckoutStepsComponent,
    CheckoutBtnComponent,
  ],
})
export class SharedModule { }
