import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuService {

  public MenuList = [
    {
      name: 'Wedding Collection',
      children: [
        {
          name: 'Wedding Collection',
          children: [
            { name: 'Kurta' },
            { name: 'Kurta Pajama' },
            { name: 'Koti' },
            { name: 'Koti Set' },
            { name: 'Sherwani' },
            { name: 'Indowestern' },
            { name: 'Jodhpuri Suit' },
            { name: 'Blazer' },
            { name: 'Suit' },
            { name: 'View All' },
          ]
        },
      ]
    },
    {
      name: 'Dulha Collection',
      children: [
        {
          name: 'Dulha Collection',
          children: [
            { name: 'Sherwani' },
            { name: 'Indowestern' },
            { name: 'Jodhpuri Suit' },
            { name: 'Suit' },
            { name: 'View All' },
          ]
        },
      ]
    },
    {
      name: 'Festive Collection',
      children: [
        {
          name: 'Festive Collection',
          children: [
            { name: 'Kurta' },
            { name: 'Kurta Pajama' },
            { name: 'Pathani' },
            { name: 'Koti' },
            { name: 'Koti Set' },
            { name: 'View All' },
          ]
        },
      ]
    },
    {
      name: 'Designer Fabric',
      children: [
        {
          name: 'Designer Fabric',
          children: [
            { name: 'Jodhpuri Suit' },
            { name: 'Sherwani' },
            { name: 'Kurta' },
            { name: 'Koti' },
            { name: 'View All' },
          ]
        },
      ]
    },
    {
      name: 'Accessories',

      children: [
        {
          name: 'Accessories',
          children: [
            { name: 'Safa' },
            { name: 'Safa & Duppata' },
            { name: 'Recception Wear Gown' },
            { name: 'Recception Wear Gown' },
            { name: 'Recception Wear Gown' },
            { name: 'View All' },
          ]
        },
      ]
    },
  ]



  constructor() { }
}
