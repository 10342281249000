<div class="header-text">
    <h1 class="text-center pt-4">{{ HeaderName }}</h1>
</div>
<div class="checkout-steps d-flex justify-content-center pt-2 pb-4 ">
    <div class="col-6 col-md-4 d-flex justify-content-between align-items-center">
        <div class="make_circle" [ngClass]="Step=='1' || Step=='2' || Step=='3' ? 'active' : ''"> 1 </div>
        <div class="make_line col "></div>
        <div class="make_circle make_text_clr" [ngClass]=" Step=='2' || Step=='3' ? 'active' : ''"> 2 </div>
        <div class="make_line col "></div>
        <div class="make_circle make_text_clr" [ngClass]=" Step=='3' ? 'active' : ''"> 3 </div>
    </div>
</div>
