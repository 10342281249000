<div class="w-100 d-block d-lg-none">
    <div class="d-flex justify-content-between align-items-center px-5 footer-main-box">


        <!-- ======Home===== -->
        <div (click)="NavBtnClicked('Home')">
            <a [routerLink]="['/']">
            <div>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.779 10.6767L12.369 2.94569C11.9843 2.62539 11.4995 2.45 10.999 2.45C10.4984 2.45 10.0136 2.62539 9.62892 2.94569L0.218899 10.6767C0.157352 10.7282 0.106553 10.7913 0.0694048 10.8625C0.0322563 10.9336 0.00948516 11.0114 0.00239165 11.0913C-0.00470186 11.1712 0.00402122 11.2518 0.0280628 11.3284C0.0521044 11.405 0.0909935 11.476 0.14251 11.5376L0.533623 12.0074C0.637642 12.1316 0.786713 12.2094 0.948074 12.2237C1.10943 12.238 1.26988 12.1877 1.39415 12.0838L2.44336 11.2221V18.3381C2.44426 18.662 2.57333 18.9724 2.80234 19.2014C3.03136 19.4304 3.34171 19.5595 3.66559 19.5604H8.55451C8.87838 19.5595 9.18873 19.4304 9.41775 19.2014C9.64677 18.9724 9.77583 18.662 9.77674 18.3381V14.3659H12.2212V18.3381C12.2221 18.662 12.3512 18.9724 12.5802 19.2014C12.8092 19.4304 13.1195 19.5595 13.4434 19.5604H18.3323C18.6547 19.5597 18.9638 19.4319 19.1926 19.2049C19.4214 18.9778 19.5515 18.6697 19.5546 18.3473V11.2213L20.6061 12.0853C20.7303 12.1892 20.8908 12.2395 21.0521 12.2252C21.2135 12.2109 21.3626 12.1331 21.4666 12.0089L21.8573 11.5395C21.909 11.4778 21.948 11.4065 21.9721 11.3296C21.9961 11.2528 22.0048 11.172 21.9975 11.0918C21.9902 11.0116 21.9672 10.9337 21.9297 10.8624C21.8922 10.7912 21.841 10.7281 21.779 10.6767V10.6767ZM17.7155 17.727H14.0545V13.7548C14.0536 13.4309 13.9246 13.1206 13.6956 12.8915C13.4665 12.6625 13.1562 12.5335 12.8323 12.5326H9.16562C8.84174 12.5335 8.53139 12.6625 8.30238 12.8915C8.07336 13.1206 7.9443 13.4309 7.94339 13.7548V17.727H4.2767V9.71646L10.999 4.19274L17.7212 9.71417L17.7155 17.727Z"
                     [ngClass]="NavItems[0]['active'] ? 'mct-path-svg-active' : 'mct-path-svg'"></path>
                </svg>
            </div>

            <div class="dot-navigation-box d-flex justify-content-center align-items-center">
                <div class="dot-nav" [ngClass]="NavItems[0]['active'] ? 'active' : ''"></div>
            </div>
            </a>
        </div>

        <!-- ======Grid===== -->
        <div (click)="NavBtnClicked('Grid')">
           <a [routerLink]="'/product-listing'">
            <div>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 4.125V17.875C1 18.3958 1.18229 18.8385 1.54688 19.2031C1.91146 19.5677 2.35417 19.75 2.875 19.75H19.125C19.6458 19.75 20.0885 19.5677 20.4531 19.2031C20.8177 18.8385 21 18.3958 21 17.875V4.125C21 3.60417 20.8177 3.16146 20.4531 2.79688C20.0885 2.43229 19.6458 2.25 19.125 2.25H2.875C2.35417 2.25 1.91146 2.43229 1.54688 2.79688C1.18229 3.16146 1 3.60417 1 4.125ZM10.0625 4.125V10.0625H2.875V4.35938C2.875 4.30729 2.90104 4.25521 2.95312 4.20312C3.00521 4.15104 3.05729 4.125 3.10938 4.125H10.0625ZM2.875 11.9375H10.0625V17.875H3.10938C3.05729 17.875 3.00521 17.849 2.95312 17.7969C2.90104 17.7448 2.875 17.6927 2.875 17.6406V11.9375ZM11.9375 17.875V11.9375H19.125V17.6406C19.125 17.6927 19.099 17.7448 19.0469 17.7969C18.9948 17.849 18.9427 17.875 18.8906 17.875H11.9375ZM19.125 10.0625H11.9375V4.125H18.8906C18.9427 4.125 18.9948 4.15104 19.0469 4.20312C19.099 4.25521 19.125 4.30729 19.125 4.35938V10.0625Z"
                        [ngClass]="NavItems[1]['active'] ? 'mct-path-svg-active' : 'mct-path-svg'"></path>
                </svg>
            </div>
            <div class="dot-navigation-box d-flex justify-content-center align-items-center">
                <div class="dot-nav" [ngClass]="NavItems[1]['active'] ? 'active' : ''"></div>
            </div>
           </a>
        </div>

        <!-- ======Cart===== -->
        <div (click)="NavBtnClicked('Cart')">
          <a [routerLink]="'/my-cart'">
            <div>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.95 6H19.7V17.875C19.7 18.7344 19.3875 19.4635 18.7625 20.0625C18.1635 20.6875 17.4344 21 16.575 21H5.325C4.46563 21 3.72344 20.6875 3.09844 20.0625C2.49948 19.4635 2.2 18.7344 2.2 17.875V6H5.95C5.95 4.61979 6.43177 3.44792 7.39531 2.48438C8.3849 1.49479 9.56979 1 10.95 1C12.3302 1 13.5021 1.49479 14.4656 2.48438C15.4552 3.44792 15.95 4.61979 15.95 6ZM13.1375 3.8125C12.5385 3.1875 11.8094 2.875 10.95 2.875C10.0906 2.875 9.34844 3.1875 8.72344 3.8125C8.12448 4.41146 7.825 5.14062 7.825 6H14.075C14.075 5.14062 13.7625 4.41146 13.1375 3.8125ZM17.825 17.875V7.875H15.95V9.4375C15.95 9.69792 15.8589 9.91927 15.6766 10.1016C15.4943 10.2839 15.2729 10.375 15.0125 10.375C14.7521 10.375 14.5307 10.2839 14.3484 10.1016C14.1661 9.91927 14.075 9.69792 14.075 9.4375V7.875H7.825V9.4375C7.825 9.69792 7.73385 9.91927 7.55156 10.1016C7.36927 10.2839 7.14792 10.375 6.8875 10.375C6.62708 10.375 6.40573 10.2839 6.22344 10.1016C6.04115 9.91927 5.95 9.69792 5.95 9.4375V7.875H4.075V17.875C4.075 18.2135 4.19219 18.5 4.42656 18.7344C4.68698 18.9948 4.98646 19.125 5.325 19.125H16.575C16.9135 19.125 17.2 18.9948 17.4344 18.7344C17.6948 18.5 17.825 18.2135 17.825 17.875Z"
                        [ngClass]="NavItems[2]['active'] ? 'mct-path-svg-active' : 'mct-path-svg'"></path>
                </svg>
            </div>
            <div class="dot-navigation-box d-flex justify-content-center align-items-center">
                <div class="dot-nav" [ngClass]="NavItems[2]['active'] ? 'active' : ''"></div>
            </div>
          </a>
        </div>

        <!-- ======Account===== -->
        <div (click)="NavBtnClicked('Account')">
          <a [routerLink]="'/my-account'">
            <div>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.5156 12.875C15.9479 12.875 17.1719 13.3958 18.1875 14.4375C19.2292 15.4531 19.75 16.6771 19.75 18.1094V19.125C19.75 19.6458 19.5677 20.0885 19.2031 20.4531C18.8385 20.8177 18.3958 21 17.875 21H4.125C3.60417 21 3.16146 20.8177 2.79688 20.4531C2.43229 20.0885 2.25 19.6458 2.25 19.125V18.1094C2.25 16.6771 2.75781 15.4531 3.77344 14.4375C4.8151 13.3958 6.05208 12.875 7.48438 12.875C7.82292 12.875 8.31771 12.9792 8.96875 13.1875C9.64583 13.3958 10.3229 13.5 11 13.5C11.6771 13.5 12.3542 13.3958 13.0312 13.1875C13.7083 12.9792 14.2031 12.875 14.5156 12.875ZM17.875 19.125V18.1094C17.875 17.1979 17.5365 16.4167 16.8594 15.7656C16.2083 15.0885 15.4271 14.75 14.5156 14.75C14.4375 14.75 14.0208 14.8542 13.2656 15.0625C12.5365 15.2708 11.7812 15.375 11 15.375C10.2188 15.375 9.45052 15.2708 8.69531 15.0625C7.96615 14.8542 7.5625 14.75 7.48438 14.75C6.57292 14.75 5.77865 15.0885 5.10156 15.7656C4.45052 16.4167 4.125 17.1979 4.125 18.1094V19.125H17.875ZM14.9844 10.6094C13.8906 11.7031 12.5625 12.25 11 12.25C9.4375 12.25 8.10938 11.7031 7.01562 10.6094C5.92188 9.51562 5.375 8.1875 5.375 6.625C5.375 5.0625 5.92188 3.73438 7.01562 2.64062C8.10938 1.54688 9.4375 1 11 1C12.5625 1 13.8906 1.54688 14.9844 2.64062C16.0781 3.73438 16.625 5.0625 16.625 6.625C16.625 8.1875 16.0781 9.51562 14.9844 10.6094ZM13.6562 3.96875C12.9271 3.23958 12.0417 2.875 11 2.875C9.95833 2.875 9.07292 3.23958 8.34375 3.96875C7.61458 4.69792 7.25 5.58333 7.25 6.625C7.25 7.66667 7.61458 8.55208 8.34375 9.28125C9.07292 10.0104 9.95833 10.375 11 10.375C12.0417 10.375 12.9271 10.0104 13.6562 9.28125C14.3854 8.55208 14.75 7.66667 14.75 6.625C14.75 5.58333 14.3854 4.69792 13.6562 3.96875Z"
                        [ngClass]="NavItems[3]['active'] ? 'mct-path-svg-active' : 'mct-path-svg'"></path>
                </svg>
            </div>
            <div class="dot-navigation-box d-flex justify-content-center align-items-center">
                <div class="dot-nav" [ngClass]="NavItems[3]['active'] ? 'active' : ''"></div>
            </div>
          </a>
        </div>
    </div>
</div>