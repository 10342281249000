<app-checkout-steps [HeaderName]="'Payment'" [Step]="3"></app-checkout-steps>


<div class="col-12 col-md-12 px-md-4 product-view-section container-md p-0 ">
    <div class="row product-view-section-stiky m-0 justify-content-between px-md-3 py-md-2">

        <div class="col-12 col-xl-8 pb-3 pt-3 p-0 pt-xl-0 leftbar position-relative">

            <div class="px-xl-3 pb-4">
                <div class="col-12 col-xl-10 prefix-deatils-box">
                    <div class="d-flex row prefix-deatils-list">
                        <div class="col row">
                            <div class="col col-xl-4 prefix-deatils-1">Contact</div>
                            <div class="col-12 col-xl prefix-deatils-2">Email&#64;email.com</div>
                        </div>
                        <div class="col-auto prefix-deatils-3 d-flex align-items-center"><a routerLink="/check-out" >Change</a></div>
                    </div>
                    <div class="d-flex row prefix-deatils-list">
                        <div class="col row">
                            <div class="col col-xl-4 prefix-deatils-1">Ship to</div>
                            <div class="col-12 col-xl prefix-deatils-2">Full Address & Pincode
                            </div>
                        </div>
                        <div class="col-auto prefix-deatils-3 d-flex align-items-center"><a  routerLink="/check-out" >Change</a></div>
                    </div>
                    <div class="d-flex row prefix-deatils-list">
                        <div class="col row">
                            <div class="col col-xl-4 prefix-deatils-1">Shipping method</div>
                            <div class="col-12 prefix-deatils-2 col-xl d-flex">
                                <div>Standard </div>
                                <div> : <strong class="delivery-type"> Free</strong></div>
                            </div>
                        </div>
                        <div class="col-auto prefix-deatils-3 d-flex align-items-center"><a  routerLink="/my-cart" >Change</a></div>
                    </div>
                </div>
            </div>
            <!-- --------------------- PAYMENT ------------------------ -->
            <div class="px-3 pt-3" style=" border-top: 1px solid #f0f0f0">
                <h5>Payment Options</h5>
                <div class="mt-4">
                    <!-- first payment type section -->
                    <div (click)="UPISELECT()">
                        <div class="d-flex align-items-center payment-select-box mb-1">
                            <div class="col-auto"><input type="radio" [checked]="UpiRadio" id="upi-payment"
                                    name="PaymentTypes"></div>
                            <div class="col-1 "><Label for="upi-payment"
                                    class=" ps-3 payment-types-headings mb-2 mb-xl-0">UPI</Label></div>
                        </div>
                        <div class="payment-overflow-hide" [ngClass]="UpiRadio ? 'payment-show1' : 'payment-hide1' ">
                            <div class="d-flex">
                                <div class="col-12 col-md-10 col-xl-6 "><input class="form-control form-control-sm"
                                        type="text" placeholder="Enter UPI Number"
                                        aria-label=".form-control-sm example"></div>
                            </div>
                            <div class="">
                                <div class="col-md-10 col-xl-6  mt-3">
                                   <a [routerLink]="['/order-confirm']"> <div class="Payment-btn"> Continue </div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Second payment type section -->
                    <div (click)="CARDSELECT()">

                        <div class="d-flex align-items-center payment-select-box mb-1 mt-3">
                            <input type="radio" id="card-payment" [checked]="CardRadio" name="PaymentTypes"> <Label
                                for="card-payment" class=" ps-3 payment-types-headings mb-1 mb-xl-0">Credit / Debit / ATM
                                Card</Label>
                        </div>
                        <div class="payment-overflow-hide" [ngClass]="CardRadio ? 'payment-show2' : 'payment-hide2' ">
                            <div class="col-12 col-md-10 col-xl-6  mt-3">
                                <input class="form-control form-control-sm" type="text" placeholder="Enter Card Number"
                                    aria-label=".form-control-sm example">
                            </div>
                            <div class="row col-12 col-md-10 col-xl-6 m-0">
                                <div class="col-10 mt-3 ps-0">
                                    <input class="form-control form-control-sm" type="text" placeholder="Enter Validity"
                                        aria-label=".form-control-sm example">
                                </div>
                                <div class="col-2 px-0 mt-3">
                                    <input class="form-control form-control-sm" type="text" placeholder="Cvv"
                                        aria-label=".form-control-sm example">
                                </div>
                            </div>
                            <div class="col-md-10 col-xl-6 mt-3">
                                <div class="Payment-btn">Pay </div>
                            </div>
                        </div>
                    </div>
                    <!-- Third payment type section -->
                    <div (click)="DELIVERYSELECT()">
                        <div class="mb-4">
                            <div class="d-flex align-items-center payment-select-box mt-3"><input type="radio"
                                    id="cash-on-delivery" [checked]="DeliveryRadio" name="PaymentTypes"> <Label
                                    for="cash-on-delivery" class=" ps-3 payment-types-headings mb-1 mb-xl-0">Cash On
                                    Delivery</Label> </div>
                            <div class="col-md-10 col-xl-6 payment-overflow-hide mt-3"
                                [ngClass]="DeliveryRadio ? 'payment-show3' : 'payment-hide3' ">
                                <div class="Payment-btn"> Continue </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- ----====----====----====----====----==== SIDE BAR ====----====----====----====----====---- -->

        <div class="col-12 px-0 pb-1 col-xl-4">
            <div class="sidebar">

                <div class="show-hide-summary px-1 d-flex d-xl-none justify-content-center" (click)="ProductToggle()">
                    <div class="side-heading d-flex px-4 justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center me-3"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="18" height="18" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                                    <path
                                        d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                </svg></div>
                            <div *ngIf="ShowHideProducts" class="d-flex align-items-center">Show Order Summary <span
                                    class="d-flex align-items-center ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </span></div>
                            <div class="d-flex align-items-center" *ngIf="!ShowHideProducts">Hide Order Summary
                                <span class="d-flex align-items-center ms-2"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16" fill="currentColor" class="bi bi-chevron-up"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                    </svg></span>
                            </div>
                        </div>

                        <div style="color:black">
                            <h6>&#8377; 1500</h6>
                        </div>
                    </div>
                </div>

                <div class="Summary-Show-Hide" [ngClass]="ShowHideProducts ? 'summary-hide' : 'summary-show' ">
                    <div class="d-none d-xl-flex justify-content-center">
                        <div class="side-heading d-flex px-3 justify-content-between">
                            <div>
                                <h4>{{ CartImgs.length | number }} Items</h4>
                            </div>
                            <div>
                                Edit
                            </div>
                        </div>
                    </div>

                    <div class="d-flex my-3 px-3 justify-content-center"  *ngFor="let cart of CartImgs; index as i">
                        <div class="col-3 px-2">
                            <div class="product-image ratio" [style.background-image]="'url( ' + cart.img + ')'"></div>
                        </div>
                        <div class="ps-2 col-9">
                            <div><strong>&#8377; {{ cart.price | number }}</strong></div>
                            <div>{{ cart.description }}</div>
                            <div>Qty: <strong> 1</strong></div>
                        </div>
                    </div>

                    <div class=" subtotal pt-3 px-3">
                        <div class="d-flex pb-1 justify-content-between">
                            <div>Subtotal</div>
                            <div>sub price</div>
                        </div>
                        <div class="d-flex pb-1 justify-content-between">
                            <div>Delivery</div>
                            <div>Free</div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div><strong>Total Pay</strong></div>
                            <div><strong>&#8377; {{ totalPrice | number}}</strong></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>