    <div class="content-header d-flex justify-content-center">
        <h5 class="d-none d-xl-block">My Profile</h5>
        <h4 class="d-xl-none">My Profile</h4>
    </div>
    <app-user-profile-card class="d-xl-none"></app-user-profile-card>
    <div class="row mt-xl-4 mt-3">
        <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">First name</label>
            <input class="form-control mb-3"
                type="text" placeholder="Enter first name" aria-label="default input example">
        </div>
        <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Last name</label>
            <input class="form-control mb-3"
                type="text" placeholder="Enter last name" aria-label="default input example">
        </div>
        <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Email</label>
            <input class="form-control  mb-3"
                type="text" placeholder="enter email" aria-label="default input example">
        </div>
        <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Phone number</label>
            <input class="form-control mb-3"
                type="text" placeholder="Enter phone number" aria-label="default input example">
        </div>
        <div class="col-12">
            <label for="exampleFormControlInput1" class="form-label">Address</label>
            <textarea class="form-control mb-3"
                type="text" placeholder="Enter address" aria-label="default input example"
                style="height: 100px"></textarea></div>
        <div class="col-12 mb-3 mt-2 d-flex justify-content-center">
            <div class="col-12 col-md-4"><app-normal-btn [btnText]="'Submit'"
                    [btnColor]="'#000'"></app-normal-btn></div>
        </div>
    </div>
