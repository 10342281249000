<div class="cart-box" (click)="toggleCart()">
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
    <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
  </svg> -->
  
  <!-- <svg>
    <use href="assets/svg/icon-sprite.svg#stroke-ecommerce"></use>
  </svg> -->
  <!-- <span class="badge rounded-pill badge-primary">2</span> -->
</div>

<div class="cart-dropdown onhover-show-div" [class.active]="openCart">
  <h6 class="f-18 mb-0 dropdown-title">Cart</h6>
  <ul>
    <li>
      <div class="media">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="" />
        <div class="media-body">
          <span>Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend"> <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span> <input class="form-control input-number" type="text" name="quantity" value="1" /><span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span
              >
            </div>
          </div>
          <h6 class="font-primary">$500</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="#"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
        </div>
      </div>
    </li>
    <li>
      <div class="media">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="" />
        <div class="media-body">
          <span>Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend"> <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span> <input class="form-control input-number" type="text" name="quantity" value="1" /><span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span
              >
            </div>
          </div>
          <h6 class="font-primary">$500.00</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="#"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
        </div>
      </div>
    </li>
    <li class="total">
      <h6 class="mb-0">Order Total : <span class="f-right">$1000.00</span></h6>
    </li>
    <li class="text-center"><a class="d-block mb-3 view-cart f-w-700" href="cart.html">Go to your cart</a><a class="btn btn-primary view-checkout" href="checkout.html">Checkout</a></li>
  </ul>
</div>
