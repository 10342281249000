<!-- <div class="container">
    <div class="featured-one my-2">
        <div class="d-flex justify-content-between flex-column flex-md-row  slide-featured-one">
            <div class=" my-2 d-flex align-items-center flex-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor"
                        class="bi bi-truck" viewBox="0 0 16 16">
                        <path
                            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                </div>
                <div class="col-12 d-flex flex-column align-items-center">
                    <div class="featured-heading">Free Shipping Return</div>
                    <div><small>Free shipping on all order over $99</small></div>
                </div>
            </div>
            <div class=" my-2 d-flex align-items-center flex-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                        class="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path
                            d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                    </svg>
                </div>
                <div class="col-12 d-flex flex-column align-items-center">
                    <div class="featured-heading">Money back guarantee</div>
                    <div><small>100% money back guarantee</small></div>
                </div>
            </div>
            <div class=" my-2 d-flex align-items-center flex-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                        class="bi bi-info-lg" viewBox="0 0 16 16">
                        <path
                            d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                    </svg>
                </div>
                <div class="col-12 d-flex flex-column align-items-center">
                    <div class="featured-heading">Online support 24/7</div>
                    <div><small>Lorem ipsum dolor sit amet.</small></div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="mt-4 mb-2">
    <!-- You can now use your library component in app.component.html -->
    <ngx-slick-carousel class="overflow-hidden carousel container" #slickModal="slick-carousel"  [config]="slideConfig">
        <div ngxSlickItem class="slide d-flex justify-content-center justify-content-md-start">
            <div class=" my-2 d-flex align-items-center flex-row flex-md-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor"
                        class="bi bi-truck" viewBox="0 0 16 16">
                        <path
                            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                </div>
                <div class="col-12 d-md-flex justify-content-center align-items-center flex-md-column d-lg-block">
                    <div class="featured-heading">Free Shipping Return</div>
                    <div><small>Free shipping on all order over $99</small></div>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide d-flex justify-content-center justify-content-md-center">
            <div class=" my-2 d-flex align-items-center flex-row flex-md-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                        class="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path
                            d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                    </svg>
                </div>
                <div class="col-12 d-md-flex justify-content-center align-items-center flex-md-column d-lg-block">
                    <div class="featured-heading">Money back guarantee</div>
                    <div><small>100% money back guarantee</small></div>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide d-flex justify-content-center justify-content-md-end">
            <div class=" my-2 d-flex align-items-center flex-row flex-md-column flex-lg-row">
                <div class="col-12 featured-logo me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                        class="bi bi-info-lg" viewBox="0 0 16 16">
                        <path
                            d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                    </svg>
                </div>
                <div class="col-12 d-md-flex justify-content-center align-items-center flex-md-column d-lg-block">
                    <div class="featured-heading">Online support 24/7</div>
                    <div><small>Lorem ipsum dolor sit amet.</small></div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div>