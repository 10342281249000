<div class="content-header d-flex justify-content-between justify-content-xl-between align-items-center">
    <h5 class="d-none d-xl-block mb-0">Shipping Address</h5>
    <h4 class="d-xl-none mb-0">Shipping Address</h4>
    <a [routerLink]="['add']">
        <button class="btn address-add">Add</button>
    </a>
</div>
<div class="row mt-3">
    <div class="px-3">
        <div class="row">
            <div class="col-12 col-md-6 mb-3"  *ngFor="let item of shippingAddress;index as i">
                <!-- <div class="radio radio-dark">
                    <input class="form-check-input" [id]="'radio'+i" type="radio" name="radio1" value="option1" [checked]="item.checked"/>
                    <label class="form-check-label m-0" [for]="'radio'+i"><strong>Address {{ i+1 }}</strong></label>
                </div> -->
                <div class="address-box" [ngClass]="item.default ? 'active' : ''" (click)="defaultAddress(i)">
                    <div class="d-flex justify-content-between"><strong>{{ item.firstName + ' ' + item.lastName }}</strong> <div *ngIf="item.default == true" class="default-badge ms-3"><small>Default</small></div></div>
                    <div>{{ item.email }}</div>
                    <div>{{ item.mobileNo }}</div>
                    <div>{{ item.addressLine1 }}</div>
                    <div>{{ item.addressLine2 }}</div>
                    <div>{{ item.townCity + ', ' + item.state + ', ' + item.pincode }}</div>
                    <div>{{ item.country }}</div>
                    <div class="d-flex justify-content-end align-items-center">
                        <a [routerLink]="['edit',i]" class="address-edit">
                            Edit
                        </a>
                        <div (click)="deleteAddress(i)" class="d-flex justify-content-end align-items-center ps-2 text-danger"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                          </svg></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>