import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wish-icon',
  templateUrl: './wish-icon.component.html',
  styleUrls: ['./wish-icon.component.scss']
})
export class WishIconComponent {
@Input() wish:boolean = false;
}
