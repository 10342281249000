import { Component } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  ShowHideOldPassword = false
  ShowHideNewPassword = false
  ShowHideCnfPassword = false
  EyeOldShowHide(){
    this.ShowHideOldPassword = !this.ShowHideOldPassword
  }
  EyeNewShowHide(){
    this.ShowHideNewPassword = !this.ShowHideNewPassword
  }
  EyeCnfShowHide(){
    this.ShowHideCnfPassword = !this.ShowHideCnfPassword
  }
}
