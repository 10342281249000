import { Component } from '@angular/core';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent {
collections = [
  // {name:'White Sherwani' , img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51247.jpg?crop=center&height=1100&v=1701758195&width=800'},
  {name:'Groom Wear' , img:'https://ethnicstar.com/cdn/shop/files/51726_1.jpg?crop=center&height=1100&v=1700461067&width=800'},
  {name:'Kurta Pajama' , img:'https://ethnicstar.com/cdn/shop/files/51582_1.jpg?crop=center&height=1100&v=1701233709&width=800'},
  {name:'Jodhpuri Suit' , img:'https://ethnicstar.com/cdn/shop/files/45556_1.jpg?crop=center&height=1100&v=1685619125&width=800'},
  {name:'Koti' , img:'https://ethnicstar.com/cdn/shop/files/47864_1.jpg?crop=center&height=1100&v=1689659501&width=800'},
  {name:'Blazer' , img:'https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrown40698-_1.jpg?crop=center&height=1100&v=1676098592&width=800'},
  {name:'Pathani Suit' , img:'https://ethnicstar.com/cdn/shop/files/47802_1.jpg?crop=center&height=1100&v=1696658222&width=800'},
];
}
