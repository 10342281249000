import { Component } from '@angular/core';
import { SearchVariableService } from '../../services/search-variable.service';

@Component({
  selector: 'app-search-btn',
  templateUrl: './search-btn.component.html',
  styleUrls: ['./search-btn.component.scss']
})
export class SearchBtnComponent {
  constructor( public searchToggle:SearchVariableService ) {}
}
