<div class="main-menu">

    <div class="px-3 back-color pb-2">
        <div class="menu-toggle-box d-flex">
            <div class="col  home-toggle-btn-text home-menu-btn d-flex justify-content-center align-items-center"
                (click)="Menu()" [ngClass]="HomeActive ? 'active' : '' ">Menu</div>
            <div class="col  home-toggle-btn-text home-account-btn d-flex justify-content-center align-items-center"
                (click)="Account()" [ngClass]="HomeActive ? '' : 'active' ">Account</div>
            <div class="toggle-move-btn" [ngClass]="HomeActive ? '' : 'active' "></div>
        </div>
    </div>

    <div class="position-relative overflow-x-hidden">
        <div class="w-100 menu-list-box position-absolute"
            [ngClass]="HomeActive ? 'menu-list-active' : 'menu-list-in-active'">
            <div class="w-100 menu-list position-absolute"
                [ngClass]=" MenuSubActive ? 'menu-sub-list-in-active' : 'menu-sub-list-active' ">

                <ul ngbNav #nav1="ngbNav" class="menu-ul">

                    <li [ngbNavItem]="j" (click)="MenuValue()" class="menu-li"
                        *ngFor="let List of menuList; j as index"><a ngbNavLink>{{ List.name | uppercase }}</a>
                        <ng-template ngbNavContent>
                            <div class="menu-ul">
                                <div class="menu-li p-0" *ngFor="let items of List.children; i as index">
                                    <div class="text-danger d-flex justify-content-between" #ref
                                        style="padding: 15px 25px;" (click)="list_menu_toggle(ref)">
                                        <div class="text-secondary">{{ items.name }} </div>
                                        <div class="rotate0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                fill="currentColor" stroke="black" class="bi bi-chevron-down"
                                                viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="product-list pt-1 d-none">
                                        <a [routerLink]="['product-listing']" (click)="MenuClose()" class="d-block" *ngFor="let item of items.children">{{ item.name }}<small></small></a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="w-100 menu-sub-list position-absolute"
                [ngClass]=" MenuSubActive ? 'menu-sub-list-active' : 'menu-sub-list-in-active' ">
                <div class="d-flex back-arrow py-3 ps-4 align-items-center" (click)="MenuShow()">
                    <span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                        </svg></span>
                </div>
                <div [ngbNavOutlet]="nav1" class=""></div>
            </div>
        </div>

        <div class="w-100 account-list-box position-absolute"
            [ngClass]="HomeActive ? 'account-list-in-active' : 'account-list-active'">
            <div class="w-100 account-list position-absolute"
                [ngClass]=" AccountSubActive ? 'account-sub-list-in-active' : 'account-sub-list-active' ">
                <app-user-menu></app-user-menu>
            </div>
        </div>

    </div>
</div>