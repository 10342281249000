import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModal1Content, NgbdModalContent } from 'src/app/components/bonus-ui/base/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class SharedVariableService {
public OffFilter:boolean;
public Menu:boolean;

constructor(config: NgbModalConfig, private modalService: NgbModal) {
  // customize default values of modals used by this component tree
  config.backdrop = 'static';
  config.keyboard = false;

}

public openCustomModal(TrackerModel){
  this.modalService.open(TrackerModel);  
}

 public openModal() {
  const modalRef = this.modalService.open(NgbdModalContent);
  modalRef.componentInstance.name = 'Kyu';
}

public openVerticallyCentered(content){
  this.modalService.open(content, { centered: true });
}
}
