<div class="w-100 navigation-bar">
    <div class="d-flex w-100 mx-4 justify-content-center">
       <div>
        <ul class="nav flex-column flex-lg-row" [ngClass]="nav_gap ? 'navigation-bar-gap' : '' ">
            <li class="nav-items"> {{ 'Shirt fabric' | uppercase }}</li>
            <li class="nav-items"> {{ 'Trouser fabric' | uppercase }}</li>
            <li class="nav-items"> {{ 'Indian ethnic fabric' | uppercase }}</li>
            <li class="nav-items">{{ 'Brand' | uppercase }}</li>
            <li class="nav-items"> {{ 'Fabric type' | uppercase }}</li>
            <li class="nav-items"> {{ 'Ethenic wear' | uppercase }}</li>
            <li class="nav-items"> {{ 'Accessories' | uppercase }}</li>
        </ul>
       </div>
    </div>
</div>