import { Component } from '@angular/core';

@Component({
  selector: 'app-category-banner',
  templateUrl: './category-banner.component.html',
  styleUrls: ['./category-banner.component.scss']
})
export class CategoryBannerComponent {
banners = [ 
  // {name:'SHERWANI', img:'https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrown43786_d7c66418-3117-4668-a542-eca3784282c9.jpg?crop=center&height=1100&v=1681131196&width=800'},
  // {name:'INDO WESTERN', img:'https://ethnicstar.com/cdn/shop/files/50750_1.jpg?crop=center&height=1100&v=1699853147&width=800'},
  // {name:'JODHPURI', img:'https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrown40730-_2.jpg?crop=center&height=1100&v=1676120875&width=800'},
  {name:'SHERWANI', img:'https://ethnicstar.com/cdn/shop/files/52063_1.jpg?crop=center&height=1100&v=1701932274&width=800'},
  {name:'INDO WESTERN', img:'https://ethnicstar.com/cdn/shop/files/52056_1.jpg?crop=center&height=1100&v=1701930865&width=800'},
  {name:'JODHPURI', img:'https://ethnicstar.com/cdn/shop/files/52038_1.jpg?crop=center&height=1100&v=1701862064&width=800'},
   
]
}
