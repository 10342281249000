import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkout-btn',
  templateUrl: './checkout-btn.component.html',
  styleUrls: ['./checkout-btn.component.scss']
})
export class CheckoutBtnComponent {
  @Input() MctBtnClr='#fff'
  @Input() MctBtnText="Button"
  @Input() MctBtnPadding=""
}
