<div class="overflow-scroll">
    <table class="table" style="width: max-content;">
        <thead> 
            <tr>
                <th>Order #</th>
                <th>Date</th>
                <th>Name</th>
                <th>Location</th>
                <th>Payment</th>
                <th>Total</th>
                <th>Status</th>
                <th>Details</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cart of CartImgs">
                <td>{{ cart.OrderId }}</td>
                <td>{{ cart.OrderDate }}</td>
                <td>{{ cart.name }}</td>
                <td>Pali, Rajasthan</td>
                <td>{{ cart.PayMode }}</td>
                <td>&#8377; {{ cart.SellingPrice }}</td>
                <td>
                    <div [ngClass]="cart.status == 1 ? 'd-flex' : 'd-none' "
                        class="order-status-pending badge-light-warning">Pending</div>
                    <div [ngClass]="cart.status == 2 ? 'd-flex' : 'd-none' "
                        class="order-status-delivery badge-light-success">Delivered</div>
                    <div [ngClass]="cart.status == 3 ? 'd-flex' : 'd-none' "
                        class="order-status-cancel badge-light-danger">Cancel</div>
                </td>
                <td><app-order-detail-btn [TrackerModel]="TrackerModel"></app-order-detail-btn></td>

                <div class="card-body">
                  <ng-template #TrackerModel let-c="close" let-d="dismiss">
                      <div class="modal-header">
                        <h6 class="modal-title" id="modal-basic-title"> Order Id : {{ cart.OrderId }}</h6>
                        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
                      </div>
                    <div class="modal-body">
                      
                      <div class="mx-md-3 py-1">
                        <div class="d-flex justify-content-between">
                          <div><h6>Hi {{ cart.name }}</h6></div>
                          <div>Expected Arrival 01-10-2023</div>
                        </div>
                        <div class="row mt-1 d-flex justify-content-center">
                          <div class="trackbar mx-2 row d-flex flex-column" *ngFor=" let TrackItem of cart.TrackerOBJ">
                            <div class="col-auto d-flex"><div class="track-point" [ngClass]="((TrackItem.Position) || (TrackItem.Progress)) ? 'active' :''"   ></div></div>
                            <div class="col d-flex position-relative  ">
                              <div class="track-line my-1" [ngClass]="TrackItem.Progress ? 'active' : '' "></div>
                              <div class="ms-3 tracker-details position-relative col">
                                <div class="d-flex justify-content-between"><span class="tracker-status" [ngClass]="TrackItem.Position ? 'active' : '' ">{{ TrackItem.Status }}</span>
                                   <div class="tracker-date-time" *ngIf="TrackItem.Position" >
                                    <span class="me-2"><small>{{ TrackItem.Date }}</small></span>
                                    <span> <small>{{ TrackItem.Time }}</small> </span>
                                  </div>
                                </div>
                                <div><small class="tracker-msg" *ngIf="TrackItem.Position"  [ngClass]="TrackItem.Position ? 'active' : '' ">{{ TrackItem.Msg }}</small></div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- <div class="modal-footer">
                      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
                    </div> -->
                  </ng-template>
                  <!-- <button class="btn btn-lg btn-outline-primary" (click)="hello(TrackerModel)">Launch demo modal</button> -->
                </div>
            </tr>
        </tbody>
    </table>
</div>