import { Component } from '@angular/core';

@Component({
  selector: 'app-new-arrival',
  templateUrl: './new-arrival.component.html',
  styleUrls: ['./new-arrival.component.scss']
})
export class NewArrivalComponent {
  new_arrivals = [ 
    {wish:false,name:'Art Silk Black Embroidered Indowestern Set' , img:'https://ethnicstar.com/cdn/shop/files/52063_1.jpg?crop=center&height=1100&v=1701932274&width=800',price:'9949'},
    {wish:false,name:'Beige Embroidered Indowestern In Art Silk' , img:'https://ethnicstar.com/cdn/shop/files/52056_1.jpg?crop=center&height=1100&v=1701930865&width=800' ,price:'11399'},
    {wish:false,name:'Designer Cream Indowestern In Art Silk Fabric' , img:'https://ethnicstar.com/cdn/shop/files/52038_1.jpg?crop=center&height=1100&v=1701862064&width=800' ,price:'18899'},
    {wish:false,name:'Jacket Style Sequins Embroidered Indowestern Set' , img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51225.jpg?crop=center&height=1100&v=1701683631&width=800  ' ,price:'9995'},
    {wish:false,name:'Jacket Style Embroidered Indowestern Set For Men' , img:'https://ethnicstar.com/cdn/shop/files/52052_1.jpg?crop=center&height=1100&v=1701930120&width=800' ,price:'13549'},
    {wish:false,name:'Cream Embroidered Silk Jodhpuri Suit' , img:'https://ethnicstar.com/cdn/shop/files/MJS50705_1.jpg?crop=center&height=1100&v=1698234260&width=800' ,price:'14995'},
  ]
}
