<div class="col-12">
    <div class="order-address-box mx-xl-2">
        <div class="order-delivery-address px-3 py-2">
            <h6 class="m-0">Delivery Address</h6>
        </div>
        <div class="px-3 py-2">
            <div class="delivery-address-name"><strong>Name</strong></div>
            <div class="delivery-address">
                <small>245-H Sashtri Street, Old Temple, Pali</small>
            </div>
            <div class="delivery-address"><small>306401, Rajasthan</small></div>
            <div class="delivery-address">
                <span>Phone : </span><small>09578145454</small>
            </div>
        </div>
    </div>

    <div>
        <div class="mb-4 mt-4" *ngFor="let orders of OrderImgs">
            <div class="cart-products row mb-1 px-xl-2 py-1">
                <div class="col-4 col-md-3 col-xl-2">
                    <div class="cart-image ratio" [ngStyle]="{ 'background-image': 'url(' + orders.img + ')' }"></div>
                </div>
                <div class="col p-0">
                    <div class="col d-block d-md-flex justify-content-between">
                        <div class="col px-3">
                            <div class="mb-2 cart-product-title">
                                E-com muscle fit cropped rib T-shir
                            </div>
                            <div>
                                <strong class="mb-2 cart-product-price me-2">&#8377; {{ orders.price }}</strong>
                                <small><strong class="cart-product-real-price">&#8377;<s> {{ orders.mrp }}</s></strong></small>
                            </div>
                            <div class="mb-3 d-flex align-items-center">
                                <div class="cart-produxt-color-box">
                                    <div class="cart-produxt-color" [ngStyle]="{ 'background-color': orders.Color }">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex flex-column justify-content-between">
                            <div class="status-box mt-1" *ngIf="orders.status == 1">
                                <div class="d-flex">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="status-ring1"></div>
                                    </div>
                                    <div class="ms-2 status-text">Order Cancel</div>
                                </div>
                                <div>
                                    <small class="status-msg">Your Payment was not confirmed by the bank</small>
                                </div>
                            </div>
                            <div class="d-flex status-box mt-1" *ngIf="orders.status == 2">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="status-ring2"></div>
                                </div>
                                <div class="ms-2 status-text">Order Placed</div>
                            </div>
                            <div class="status-box mt-1" *ngIf="orders.status == 3">
                                <div class="d-flex">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="status-ring3"></div>
                                    </div>
                                    <div class="ms-2 status-text">Deliverd on Oct 01, 2023</div>
                                </div>
                                <div>
                                    <small class="status-msg">Your item has been deliverd</small>
                                </div>
                                <div>
                                    <a href="#">
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10"
                                                fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                            </svg></span>
                                        Rate & Review Product</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </div>

        <div class="mx-xl-2 mb-4 mb-md-0">
            <div class="px-3 py-2 col-12 col-lg-6 order-status-box">
                <div class="order-status d-flex justify-content-between">
                    <span>Status</span> <span class="order-status-badge badge-light-success"><small>{{ 'completed'| uppercase }}</small></span>
                </div>
                <div class="order-pay-mode d-flex justify-content-between">
                    <span>Payment Mode</span> <span class="order-payment-badge"><small>{{ 'cod'| uppercase }}</small></span>
                </div>
                <div class="order-total-qty d-flex justify-content-between">
                    <span>Total Qty</span> <span class="order-total-qty-badge"><small>{{ OrderImgs.length | number }} items</small></span>
                </div>
                <div class="order-total-amount d-flex justify-content-between">
                    <span>Total Amount</span> <span class="order-total-amount-badge"><small>&#8377; {{ totalPrice | number }}</small></span>
                </div>
            </div>
        </div>
    </div>
</div>