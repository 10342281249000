<div class="card card-body mb-2 mt-2 mt-xl-4 user-detail-section">
    <div class="bg-image d-none d-md-block"></div>
    <div class="user-image-box position-relative">
        <div class="d-flex justify-content-center position-relative image">
            <div class="user-image"></div>
        </div>
        <div class="d-flex mt-2 mt-md-0 justify-content-center">
            <h6>Hero</h6>
        </div>
    </div>
</div>
