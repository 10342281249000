<div class="content-header d-flex justify-content-center justify-content-xl-start">
    <h5 class="d-none d-xl-block">Shipping Address</h5>
    <h4 class="d-xl-none">Shipping Address</h4>
</div>
 <div class="row mt-3">
    <div class="px-3">
        <form [formGroup]="shippingForm" (ngSubmit)="OnSubmit()">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">First
                            name</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1"
                            placeholder="Enter first name" formControlName="firstName">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Last
                            name</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Last name" formControlName="lastName">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Mobile
                            number</label>
                        <input type="number" class="form-control" id="exampleFormControlInput1"
                            placeholder="Enter mobile number" formControlName="mobileNo">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Email</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter email" formControlName="email">
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Address
                            line 1</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1"
                            placeholder="Enter address line 1" formControlName="addressLine1">
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Address
                            line 2</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1"
                            placeholder="Enter address line 2" formControlName="addressLine2">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Town/City</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Town/City" formControlName="townCity">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">State</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Select state" formControlName="state">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Postcode</label>
                        <input type="number" class="form-control" id="exampleFormControlInput1"
                            placeholder="Enter postcode" formControlName="pincode">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Country/Region</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1"
                            placeholder="Select Country/Region" formControlName="country">
                    </div>
                </div>
                <div class="col-12 mb-3 mt-4 d-flex ">
                    <div class="col-12 col-md-3"><app-normal-btn [btnText]="'Submit'" [btnColor]="'#000'"></app-normal-btn>
                    </div>
                </div>
            </div>
        </form>
      
    </div>
</div>