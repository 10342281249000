import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class ShippingEditComponent {

  shippingForm: FormGroup;

  id: number;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.id = params.id

      this.shippingForm = new FormGroup({
        firstName: new FormControl(this.shippingAddress[this.id].firstName),
        lastName: new FormControl(this.shippingAddress[this.id].lastName),
        mobileNo: new FormControl(this.shippingAddress[this.id].mobileNo),
        email: new FormControl(this.shippingAddress[this.id].email),
        addressLine1: new FormControl(this.shippingAddress[this.id].addressLine1),
        addressLine2: new FormControl(this.shippingAddress[this.id].addressLine2),
        townCity: new FormControl(this.shippingAddress[this.id].townCity),
        state: new FormControl(this.shippingAddress[this.id].state),
        pincode: new FormControl(this.shippingAddress[this.id].pincode),
        country: new FormControl(this.shippingAddress[this.id].country),
      })
    })
  }

  shippingAddress = [
    {
      firstName: 'Jeevan',
      lastName: 'Singh',
      mobileNo: '9856422545',
      email: 'jeevansingh@gmail.com',
      addressLine1: '38F Block C, Sharan Nagar',
      addressLine2: '',
      townCity: 'Pali',
      state: 'Rajasthan',
      pincode: '306401',
      country: 'India',
      default: true
    },
    {
      firstName: 'Indra',
      lastName: 'Singh',
      mobileNo: '9856422545',
      email: 'jeevansingh@gmail.com',
      addressLine1: '556 Nehru Colony,Near Mahadev Mandir',
      addressLine2: '',
      townCity: 'Pali',
      state: 'Rajasthan',
      pincode: '306401',
      country: 'India',
      default: false
    },
  ]

  OnSubmit(){

  }
}
