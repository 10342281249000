import { Component } from '@angular/core';

@Component({
  selector: 'app-order-timeline-mobile',
  templateUrl: './order-timeline-mobile.component.html',
  styleUrls: ['./order-timeline-mobile.component.scss']
})
export class OrderTimelineMobileComponent {

}
