import { Component } from '@angular/core';

@Component({
  selector: 'app-del-btn',
  templateUrl: './del-btn.component.html',
  styleUrls: ['./del-btn.component.scss']
})
export class DelBtnComponent {

}
