<div class="col-12 filter-border position-relative "> <!-- Filter section -->
  <!-- <div class="filetr-heading py-2 text-center">{{ 'Filter' | uppercase }}</div> -->
<!-- <div (click)="CheckAllSelect()">console</div> -->
  <div class="filter-box">
    <div class="filter-category w-100" [ngClass]="SubCategoryValue ? 'filter-category-hide' : 'filter-category-show' ">
      <div class="d-flex py-2 ps-4 pe-2 filter-header">
        <div class="col fliter-text d-flex align-items-center">{{ FilterName | uppercase }}</div>
        <div class="col-auto filter-close" (click)="offCanvas()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
              </svg>
        </div>
      </div>
      <div class="d-flex justify-content-end me-3"><span (click)="UnCheckAllSelect()"><small>Clear All</small></span></div>


      <ul ngbNav #nav="ngbNav" class="d-block">
        <li [ngbNavItem]="f" (click)="SubCategoryShow(item.name)"  class="filter-items" *ngFor="let item of filterItems ; index as f ">
          <a ngbNavLink>
            {{ item.name }}
            <div class="d-flex flex-wrap sub-category-stamps"><span *ngFor="let subs of filterItems[f].subItem"><small *ngIf="subs.active"> {{ subs.name}} ,</small></span></div>
          </a>
          <ng-template ngbNavContent>
            <ul class="filter-sub-items">
              <li *ngFor="let subItems of filterItems[f].subItem ; index as s"  (click)="SubCatSelect(f,s);false">
                <label [for]="item.name+s" class="d-flex  align-items-center justify-content-between">
                  <div> {{ subItems.name}} </div>
                  <div class="d-flex align-items-center"><input type="checkbox" [checked]="subItems.active" [name]="subItems.name" [id]="item.name+s" class="d-none filter-check-box"> <span class="align-items-center filter-icon" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg></span></div>
                </label>
              </li>
            </ul>
          </ng-template>
        </li>
      </ul>
    </div>

    <!-- ================================ sub-menu category ===============================  -->

    <div class="filter-sub-category w-100" [ngClass]="SubCategoryValue ? 'sub-category-show' : 'sub-category-hide' ">
      <div class="d-flex justify-content-between back-arrow py-3 ps-4 align-items-center">
        <div class="d-flex align-items-center">
          <span (click)="filterShow()"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd"  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg></span>
          <div class="ms-3 filters-title">{{ FilterTitle }}</div>
        </div>
        <div class=" d-flex align-items-center select-all  py-1 px-4" (click)="SelectAll()" *ngIf="SelectAllValue"><small>All</small></div>
        <div class=" d-flex align-items-center select-all  py-1 px-4" (click)="ClearAll()" *ngIf="ClearAllValue"><small>Clear</small></div>
      </div>
      <div [ngbNavOutlet]="nav" class=""> </div>
    </div>
  </div>
  <div class="position-fixed d-flex justify-content-center filter-apply-box">
    <button class="filter-apply-btn" (click)="ApplyFilter()">Apply Filter</button>
  </div>
</div>