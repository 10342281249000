<!--page-wrapper Start-->
<!-- <div class="page-wrapper">
    <router-outlet></router-outlet>
</div> -->
<!--page-wrapper Ends-->
<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type">
    <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
      <app-header></app-header>
      <!-- Page Body Start-->
      <div class="page-body-wrapper position-relative">
        <!-- <div class="sidebar-wrapper" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
          <app-sidebar></app-sidebar>
        </div> -->
        <div class="page-body px-0">
          <app-mobile-menu>
            <div class="HomeMenu">
              <app-home-menu></app-home-menu>
            </div>
          </app-mobile-menu>
          <main [@fadeInAnimation]="getRouterOutletState(o)">
            <router-outlet #o="outlet"></router-outlet>
          </main>
        </div>
        <!-- footer start-->
        <footer class="footer">
          <app-footer></app-footer>
        </footer>
        <app-footer-menu></app-footer-menu>
        <!-- footer End-->
        <app-video-call class="d-md-none d-block" style=" position: fixed; bottom: 20%; right:1.5rem;"></app-video-call>
        <app-search-btn class="d-md-none d-block" style=" position: fixed; bottom: 12%; right:1.5rem;"></app-search-btn>
      </div>
      <!-- Page Body End-->
    </div>
  </div>
  <!-- <div class="bg-overlay1"></div>
  <app-customizer></app-customizer> -->
  <!-- page-wrapper End-->
  