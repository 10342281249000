import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-basic-product-card',
  templateUrl: './basic-product-card.component.html',
  styleUrls: ['./basic-product-card.component.scss']
})
export class BasicProductCardComponent {
  @Input() image;
  @Input() image2;
  @Input() wish;
  @Input() name;
  @Input() price;
  @Input() mrp;
  @Input() badge;
  @Input() url = "/product-details-filter";
}
