import { Component } from '@angular/core';

@Component({
  selector: 'app-social-banner',
  templateUrl: './social-banner.component.html',
  styleUrls: ['./social-banner.component.scss']
})
export class SocialBannerComponent {
  banners = [ 
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrownMZ-4580-M1_3_1.webp?crop=center&height=1100&v=1676529965&width=800'},
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown40726-_2.webp?crop=center&height=1100&v=1676529980&width=800'},
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown41654-1_2.webp?crop=center&height=1100&v=1676530002&width=800'},
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown40525.webp?crop=center&height=1100&v=1676529994&width=800'},
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrownMZ-3010-M3_3.webp?crop=center&height=1100&v=1676530021&width=800'},
    {social_url:'#', img:'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown4662.webp?crop=center&height=1100&v=1676530034&width=800'},
  ]
  }

