import { Component } from '@angular/core';

@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.scss']
})
export class BannerHomeComponent {
  slideConfig = {   // Start configration of slick slider -------- ====
    'slidesToShow': 1,
    "autoplay": true,
    pauseOnHover: false,
    pauseOnFocus:false,
    speed: 700,
    autoplaySpeed: 4000,
    responsive: [  // Set responsive of slick slider in configration as a array -------- ====
      {  // Create responsive object-1
        breakpoint: 769,
        settings: {
          'slidesToShow': 1,
          'slidesToScroll': 1
        },

      },  // End responsive object-1
    ]  // responsive array finish
  };

  banner = [
    { name: 'Indo Western', offer: '50% OFF', img: 'https://ethnicstar.com/cdn/shop/files/52062_1.jpg?crop=center&height=1100&v=1701932130&width=800', descriotion: 'Navy Blue Thread Embroidered Indowestern Set' },
    { name: 'Jodhpuri', offer: '30% OFF', img: 'https://ethnicstar.com/cdn/shop/files/MJS50728_1.jpg?crop=center&height=1100&v=1698312481&width=800', descriotion: 'Misty Rose Pink Embroidered Mens Jodhpuri Suit' },
    { name: 'Sherwani', offer: '45% OFF', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51235.jpg?crop=center&height=1100&v=1701753340&width=800', descriotion: 'Anarkali Style White Embroidered Groom Sherwani Set' },
    { name: 'Suit', offer: '40% OFF', img: 'https://ethnicstar.com/cdn/shop/files/51598_1.jpg?crop=center&height=1100&v=1701408022&width=800', descriotion: 'Imported Blue Mens Wear Suit For Wedding' },
    { name: 'Kurta Pajama', offer: '40% OFF', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown51226.jpg?crop=center&height=1100&v=1701683853&width=800', descriotion: 'Light Sky Blue Mens Embroidered Kurta Pajama' },
  ];
}
