import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      icon: 'stroke-svg',
    },
    color: {
      primary_color: '#7366ff',
      secondary_color: '#f73164',
    },
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (this.config.settings.layout_type == 'rtl' && isPlatformBrowser(this.platformId)) {
      this.document.documentElement.setAttribute('dir', this.config.settings.layout_type);
    }

    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.style.setProperty('--theme-deafult', localStorage.getItem('primary_color') || this.config.color.primary_color);
      this.document.documentElement.style.setProperty('--theme-secondary', localStorage.getItem('secondary_color') || this.config.color.secondary_color);
      const primary = localStorage.getItem('primary_color') || this.config.color.primary_color;
      const secondary = localStorage.getItem('secondary_color') || this.config.color.secondary_color;
      this.config.color.primary_color = primary;
      this.config.color.secondary_color = secondary;
    }
  }

  setColor(primary_color: string, secondary_color: string) {
    this.config.color.primary_color = primary_color;
    this.config.color.secondary_color = secondary_color;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('primary_color', this.config.color.primary_color);
      localStorage.setItem('secondary_color', this.config.color.secondary_color);
      this.document.defaultView?.location.reload();
    }
  }

  resetColor() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
      this.document.documentElement.style.setProperty('--theme-secondary', '#f73164');
      localStorage.setItem('primary_color', '#7366ff');
      localStorage.setItem('secondary_color', '#f73164');
      this.document.defaultView?.location.reload();
    }
  }
}