import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { SharedVariableService } from "../../services/shared.service";
import { HeaderMenuService } from "../../services/header-menu.service";
import { SearchVariableService } from "../../services/search-variable.service"
import { isPlatformBrowser } from "@angular/common";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;

  navButton: boolean = false



  onResize(event: any) {
    if (window.innerWidth > 821) {
      this.navButton = true
    } else {
      this.navButton = false
    }
  }
  constructor(public sharedService: SharedVariableService, public headerMenuService: HeaderMenuService, public searchToggle: SearchVariableService, @Inject(PLATFORM_ID) private platformId: any) { }

  searchButton = this.searchToggle.searchToggle;

  Menus = this.headerMenuService.MenuList
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)){
      if (window.innerWidth > 821) {
        this.navButton = true
      } else {
        this.navButton = false
      }

    window.addEventListener('resize', this.onResize.bind(this));
    }
    // this.elem = document.documentElement;
  }

  MenuShow() {
    this.sharedService.Menu = true;
  }
  searchBtn() {
    // this.searchButton = !this.searchButton
  }
}
