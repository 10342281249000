<!-- <div class="d-flex justify-content-center">
    <div class="status-title-box position-relative">
        <div class="d-flex px-1 justify-content-between">
            <div class="status-title">Order Confirmed</div>
            <div class="status-title">Shipped</div>
            <div class="status-title">Out For Delivery</div>
            <div class="status-title">Devered</div>
        </div>
    </div>
</div> -->
<div class="d-flex justify-content-center mt-4">
    <div class="status-bar position-relative">
        <div class="status-box">
            <div class="d-flex px-1 justify-content-between">
                <div class="status-cicle-1">
                    <div class="status-title">
                        <div>Order Confirmed</div>
                    </div>
                </div>
                <div class="status-cicle-2">
                    <div class="status-title">Shipped</div>
                </div>
                <div class="status-cicle-3">
                    <div class="status-title">Out For Delivery</div>
                </div>
                <div class="status-cicle-4">
                    <div class="status-title">Delivered</div>
                </div>
            </div>
        </div>
        <div class="status-bar-fill" [style.width]="TimeLineFill"></div>
    </div>
</div>