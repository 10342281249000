import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  currency = [
    { id: 1, name: 'INR', active: true, img: 'assets/images/currency/rupee-indian.png' },
    { id: 2, name: 'EUR', active: false, img: 'assets/images/currency/euro-currency-symbol.png' },
    { id: 3, name: 'USD', active: false, img: 'assets/images/currency/dollar-currency-symbol.png' },
    { id: 4, name: 'RUB', active: false, img: 'assets/images/currency/ruble-currency-sign.png' },
  ];

  CurracyImage = 'assets/images/currency/rupee-indian.png'
  CurrencySelect(currency: string) {
    this.currency.forEach((item) => {
      if (item.name == currency) {
        this.CurracyImage = item.img;
        item.active = true
      } else {
        item.active = false
      }
    })
  }



  public openNotification: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
