
<app-checkout-steps [HeaderName]="'Cart'" [Step]="1"></app-checkout-steps>

<div class="col-12 mt-2 col-md-10 px-md-4 product-view-section container-md p-0 ">
    <div class="row m-0 d-flex justify-content-center pb-3 ">
        <!-- ------------- left side ------------- -->

        <div class="col-12 col-xl-9">
            <div>
                <div class="mb-4" *ngFor="let cart of CartImgs; index as carts">
                    <div class="d-flex mb-1 cart-products px-xl-2 py-1">
                        <div class="col-4 col-md-3 col-xl-2">
                            <div class="cart-image ratio" [ngStyle]="{'background-image':'url('+ cart.img +')'}">
                            </div>
                        </div>
                        <div class="col px-3">
                            <div class="mb-2 cart-product-title">{{ cart.description }}</div>
                            <div><strong class="mb-2 cart-product-price me-2">&#8377; {{ cart.price | number }}</strong> <small><strong
                                        class="cart-product-real-price">&#8377;<s> {{ cart.mrp | number }}</s></strong></small></div>
                            <div class="mb-3 d-flex  align-items-center">
                                <div class="col-6 col-md-3 col-xl-2 cart-product-qty">
                                    <div class=" product-quantity">
                                        <div class="quantity d-flex justify-content-evenly align-items-center">
                                            <div class="qty-btns d-flex justify-content-center"
                                                (click)="QtyMinus(carts)">-</div>
                                            <span class="qty-count">
                                                <div class="text-center" style="width: 1rem;">{{ cart.Qty }}</div>
                                            </span>
                                            <div class="qty-btns d-flex justify-content-center"
                                                (click)="QtyPlus(carts)">+</div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" mx-3">
                                    <div class="cart-produxt-color-box">
                                        <div class="cart-produxt-color" [ngStyle]="{'background-color': cart.Color}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mb-3 col-12 col-md-7 col-xl-5">
                                <div class=" col wish-delete d-flex justify-content-center align-items-center">
                                    <div class=" mx-xl-0 del-btn">
                                        <app-del-btn (click)="sum()"></app-del-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ------------- right side ------------- -->

        <div class="col-12 col-xl-3">
            <div class="cart-left-heading py-2 mb-3"><strong>Total</strong></div>
            <div>
                <div class="mb-2 d-flex justify-content-between">
                    <div><strong>Sub-total</strong></div>
                    <div>&#8377; {{ totalPrice | number}}</div>
                </div>
                <div class="mb-2 d-flex justify-content-between">
                    <div><strong>Delivery</strong></div>
                    <div>Free</div>
                </div>
                <div class="mb-3 d-flex">
                    <select class="select-delivery py-2 px-1" name="delivery-type" id="delivery-type">
                        <option value="delivery-type" disabled selected>Delivery Type</option>
                        <option value="Standard">Standard Delivery</option>
                        <option value="Express">Express Delivery</option>
                    </select>
                </div>
                <div>
                    <a [routerLink]="['/check-out']"><app-normal-btn [btnText]="'Check-Out'"
                            [btnColor]="'#000'"></app-normal-btn></a>
                </div>
                <div class="mt-3 pt-1 payment-type-img">
                    <div
                        style="background-image: url('https://assets.asosservices.com/asos-finance/images/marketing/single.png');">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>