import { Component } from '@angular/core';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent {
wishImgs = [
  { name: 'Indo Western',description:'Art Silk Black Embroidered Indowestern Set', img: 'https://ethnicstar.com/cdn/shop/files/52063_1.jpg?crop=center&height=1100&v=1701932274&width=800', mrp: '10846', price: '9949', Color: '#2d3142', Qty:1, },
  { name: 'Jodhpuri Suit',description:'Misty Rose Pink Embroidered Mens Jodhpuri Suit', img: 'https://ethnicstar.com/cdn/shop/files/MJS50728_1.jpg?crop=center&height=1100&v=1698312481&width=800', mrp: '18595', price: '17995', Color: '#c1a8a1', Qty:2, },
  { name: 'Safa & Dupatta',description:'Heavy Groom Wear Safa, Dupatta And Mojari', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown50974_1.jpg?crop=center&height=1100&v=1699184478&width=800', mrp: '7500', price: '6700', Color: '#8a0018', Qty:1, },
  { name: 'Indo Western',description:'Traditional Purple Color Indowestern In Art Silk', img: 'https://ethnicstar.com/cdn/shop/files/52061_1.jpg?crop=center&height=1100&v=1701931953&width=800', mrp: '10846', price: '9949', Color: '#41223a', Qty:1, },
  { name: 'Mojari',description:'Wedding Wear Leather Fabric Mojari', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrownMJS51312.jpg?crop=center&height=1100&v=1699182156&width=800', mrp: '1499', price: '1399', Color: '#7b4424', Qty:1, },
];
}
