<div class="container py-3">
    <div class="">
        <div class="d-flex row">
            <div class="col-12 col-lg-6 banner-image-section">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <!-- <div class="m-0 banner-image h-100" style="background-image: url(https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrownMZ-4002-M1_4.jpg?crop=center&height=1100&v=1676531412&width=800);"> -->
                    <div class="m-0 banner-image h-100" style="background-image: url(https://ethnicstar.com/cdn/shop/products/batch_ChoclateBrownMZ-4080-M2_5.jpg?crop=center&height=1100&v=1676613865&width=800);">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 banner-text-section">
                <div class="d-flex flex-column justify-content-center align-items-center h-100 text-center p-lg-5 p-0 py-3">
                    <h1>{{ "Zig Zag Work Designer Sherwani For Groom" | uppercase}}</h1>
                    <div class="d-flex align-items-center">
                        <s class="me-3"><h6 style="color: #3e3d3d;">&#8377;{{ "4989" | number}}</h6></s>
                        <h4 class="text-danger">&#8377;{{ "27999" | number}}</h4>
                    </div>
                    <h6 class="mb-3"> Up to 50% off almost everything</h6>
                    <!-- <h4>{{ "design for brides to stand out" | uppercase}}</h4> -->
                    <a routerLink="/product-details-filter">
                        <button class="banner-btn mt-3">BUY NOW</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>