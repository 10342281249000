import { Component } from '@angular/core';
import { HeaderMenuService } from '../../services/header-menu.service';
import { SharedVariableService } from '../../services/shared.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss']
})
export class HomeMenuComponent {
  constructor(private menuService: HeaderMenuService, public sharedService: SharedVariableService ,private metaService: Meta) {
   
   }
  MenuSubActive = false;
  AccountSubActive = false;
  HomeActive = true;
  Menu() {
    this.HomeActive = true;
    this.AccountSubActive = false;
  }
  Account() {
    this.HomeActive = false;
    this.MenuSubActive = false;
  }

  menuList = this.menuService.MenuList
  menu_dropdown: boolean = false
  // =================================
  list_menu_toggle(e) {
    if (e.children[1].classList == 'rotate0') {
      e.children[1].classList.add('rotate180')
      e.children[1].classList.remove('rotate0')
      e.nextElementSibling.classList.add('d-block')
      e.nextElementSibling.classList.remove('d-none')
      e.children[0].classList.add('text-danger')
      e.children[0].classList.remove('text-secondary')
    } else {
      e.children[1].classList.add('rotate0')
      e.children[1].classList.remove('rotate180')
      e.nextElementSibling.classList.add('d-none')
      e.nextElementSibling.classList.remove('d-block')
      e.children[0].classList.add('text-secondary')
      e.children[0].classList.remove('text-danger')
    }
  }

  MenuClose() {
    this.sharedService.Menu = false;
  }
  MenuValue() {
    this.MenuSubActive = true;
  }
  MenuShow() { this.MenuSubActive = false; }
}
