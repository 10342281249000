<div class="container">
    <div class="my-4">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-7  col-lg-6 d-flex flex-column align-items-center">
                <h2 class="d-none d-md-block">Get Latest fashion Updates</h2>
                <h5 class="d-md-none d-block">Get Latest fashion Updates</h5>
                <div class="input-group mb-3">
                    <input type="text" class="form-control rounded-0 input-search-box" placeholder="Recipient's username"
                        aria-label="Enter your email" aria-describedby="basic-addon2">
                    <span class="input-group-text rounded-0 input-search-box-icon" id="basic-addon2">Subscribe</span>
                </div>
            </div>
        </div>
    </div>
</div>