<!-- <div>
    <div class="ratio banner-ratio" style="background-image: url(https://www.bombayshirts.com/cdn/shop/files/newArtboard_1_b1c23d79-c39f-46dd-99e5-201dca3c37d3.jpg?v=1691566838);">
       <div class="d-flex justify-content-center align-items-center">
       </div>
    </div>
</div> -->
<div>
    <div class=" banner-ratio">
        <ngx-slick-carousel class="overflow-hidden carousel home-banner" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem  *ngFor="let item of banner">
                <div class="d-flex row">
                <div class="col-12 col-md-6 banner-image-section">
                    <div class="slide d-flex justify-content-center align-items-center h-100 overflow-hidden">
                        <figure class=" h-100 ratio ratio-1x1 m-0 banner-image"
                            [ngStyle]="{'background-image': 'url('+ item.img +')'}">
                        </figure>
                        <div class="position-absolute" style="top:60%;">
                            <h4 class="banner-off">{{ item.offer }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 banner-text-section">
                    <div
                        class="d-flex flex-column text-center justify-content-center align-items-center h-100 px-4 py-5">
                        <h6 class="mb-3"> Up to {{ item.offer }} almost everything</h6>
                        <h1>{{ item.name | uppercase}}</h1>
                        <h4>{{ item.descriotion | uppercase}}</h4>
                        <a routerLink="/product-listing">
                            <button class="banner-btn mt-3">SHOP COLLECTIONS</button>
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>