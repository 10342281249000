import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { HomeComponent } from './pages/home/home.component';
import { ProductSectionComponent } from './components/product/product-section/product-section.component';
import { ProductCardComponent } from './components/product/elements/product-card/product-card.component';
import { FeaturedOneComponent } from './components/product/elements/featured-one/featured-one.component';
import { NewsLetterComponent } from './components/product/elements/news-letter/news-letter.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductListingComponent } from './pages/product-listing/product-listing.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { AuthComponent } from './pages/auth/auth.component';
import { CheckOutComponent } from './pages/check-out/check-out.component';
import { WishListComponent } from './pages/wish-list/wish-list.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { MyCartComponent } from './pages/cart/cart.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { OrderConfirmComponent } from './pages/order-confirm/order-confirm.component';
import { UserAccountComponent } from './pages/user-account/user-account.component';
import { MyProfileComponent } from './pages/user-account/my-profile/my-profile.component';
import { ShippingComponent } from './pages/user-account/shipping/shipping.component';
import { ChangePasswordComponent } from './pages/user-account/change-password/change-password.component';
import { MyOrdersComponent } from './pages/user-account/my-orders/my-orders.component';
import { OrderListingComponent } from './pages/user-account/my-orders/order-listing/order-listing.component';
import { OrderDetailsComponent } from './pages/user-account/my-orders/order-details/order-details.component';
import { AppointmentFormComponent } from './pages/appointment-form/appointment-form.component';
import { ProductDetailWithFilterComponent } from './pages/product-detail-with-filter/product-detail-with-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShippingAddComponent } from './pages/user-account/shipping/add/add.component';
import { ShippingListComponent } from './pages/user-account/shipping/list/list.component';
import { ShippingEditComponent } from './pages/user-account/shipping/edit/edit.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProductSectionComponent,
    ProductCardComponent,
    FeaturedOneComponent,
    NewsLetterComponent,
    ProductListingComponent,
    ProductDetailsComponent,
    AuthComponent,
    CheckOutComponent,
    WishListComponent,
    OrderListComponent,
    MyCartComponent,
    PaymentComponent,
    OrderConfirmComponent,
    UserAccountComponent,
    MyProfileComponent,
    ShippingComponent,
    ChangePasswordComponent,
    MyOrdersComponent,
    OrderListingComponent,
    OrderDetailsComponent,
    AppointmentFormComponent,
    ProductDetailWithFilterComponent,
    ShippingAddComponent,
    ShippingListComponent,
    ShippingEditComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SlickCarouselModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    
  // for HttpClient use:
    LoadingBarHttpClientModule,
  // for Router use:
    LoadingBarRouterModule,
  // for Core use:
    LoadingBarModule
  ],
  exports:[
    ProductCardComponent,
  ],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppModule { }
