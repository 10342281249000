<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-12 text-center my-3">
        <h1>{{ title }}</h1>
      </div>
      <div class="col-12 text-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/'">
              <!-- <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg> -->
              Home
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
