import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent {
  NavItems=[
    {name:'Home',active:true},
    {name:'Grid',active:false},
    {name:'Cart',active:false},
    {name:'Account',active:false},
  ];
NavBtnClicked(Nav:string){
  this.NavItems.forEach((item)=>{
    if(item.name == Nav){
      item.active = true;
    }else{
      item.active = false;
    }
  })
}
}
