
<div class="container py-3">
    <div class="row">
        @for (image of banners; track $index) {
        <!-- <a routerLink="/product-listing" class="col-12 col-lg-4" *ngFor="let image of banners"> -->
        <a routerLink="/product-listing" class="col-12 col-lg-4">
            <div  class="card card-size position-relative">
              <img class="card-img-top h-100 w-100" src="{{ image.img }}" alt="" style="object-fit: cover; object-position: top;">
              <div class="position-absolute  banner-text-section"><h5 class="text-white text-center my-2">{{ image.name }}</h5></div>
            </div>
        </a>
    }
    </div>
</div>