<app-off-canvas>
  <div class="CommonCanvas">
    <app-filter></app-filter>
  </div>
</app-off-canvas>
<div class="">
  <div class="">
    <app-breadcrumb [title]="'Wedding'"  [active_item]="'Wedding'"></app-breadcrumb>
  </div>
  <div class="col-12 col-md-12 px-md-4 product-view-section container-md p-0 ">
    <div class="d-md-flex product-view-section-stiky justify-content-between align-items-center px-md-3 py-md-2">
      <div class="col-12 col-md product-category"></div>
      <div class="col-12 d-flex my-2 my-md-0 align-items-center justify-content-md-between position-relative">
        <div class="col-6 d-flex sort-box py-2 py-md-0 position-relative">

          <select class="accordion-design1" placeholder="Sort">
            <option class="text-center" disabled selected>{{ 'sort' | uppercase }}</option>
            <option class="text-center" value="">{{ 'Best Selling' | uppercase }}</option>
            <option class="text-center" value=""> {{ 'Price - Low to High' | uppercase }}</option>
            <option class="text-center" value=""> {{ 'Price - High to Low' | uppercase }}</option>
          </select>
          <div
            class="position-relative ms-md-2 me-1 d-flex justify-content-center align-items-center filter-box-mobile-view"
            (click)="offCanvas()">
            {{ 'Filter' | uppercase }}
          </div>
          <div class="vr-line-box d-flex align-items-center d-md-none">
            <div class="vr-line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center container"> <!-- divider -->
    <div class="col-12 col-md-12 px-md-4 product-view-section"> <!-- View section -->

      <div class="row mb-5">
        <div class=" py-2" [ngClass]="ColCLass" *ngFor="let items of image; index as i">
            <app-basic-product-card [image]="items.img1" [image2]="items.img2" [wish]="items.wish" 
             [price]="items.price" [mrp]="items.mrp" [badge]="items.sale" [name]="items.name"></app-basic-product-card>
        </div>
      </div>
    </div>
  </div>
</div>