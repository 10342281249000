<app-checkout-steps [HeaderName]="'Check Out'" [Step]="2"></app-checkout-steps>

<div class="col-12 col-md-12 px-lg-4 product-view-section container-lg p-0">
  <div class="row product-view-section-stiky justify-content-between px-md-3 py-md-2">

    <div class="col-12 col-lg-8 pb-3 leftbar position-relative">


      <!-- --------------------- DELLIVERY ADDRESS ------------------------ -->
      <div class="px-3">
        <h4>Delivery Address</h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">First Name</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter first name">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Last Name</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Last name">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Mobile Number</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="Enter mobile number">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter email">
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Address</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter address">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Town/City</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Town/City">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">State</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Select state">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Postcode</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="Enter postcode">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Country/Region</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Select Country/Region">
            </div>
          </div>
        </div>
      </div>
      <!-- --------------------- BILLING ADDRESS ------------------------ -->
      <div class=" mt-3 billing-box">
        <div class="px-3">
          <h4>Billing Address</h4>
          <div class="col-12 d-md-flex align-items-center">
            <div class="col-12 col-md-auto mb-2 d-flex align-items-center" (click)="Shipping()">
              <input type="checkbox" id="shipping" [checked]="AddressValue ? '' : 'checked'">
              <label class="ms-1 mb-0 form-label" name="BillingAddress">Same as shipping address</label>
            </div>
            <div class="col-12 col-md-auto mb-2 ms-md-3 d-flex align-items-center" (click)="Billing()">
              <input type="checkbox" id="billing" [checked]="AddressValue ? 'checked' : ''">
              <label class="ms-1 mb-0 form-label" name="BillingAddress">Billing to a diffrent address
                ?</label>
            </div>
          </div>
        </div>

        <div class="px-3 row billing-address-box"
          [ngClass]="AddressValue ? 'billing-address-show' : 'billing-address-hide'">

          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">First Name</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter first name">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Last Name</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Last name">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Mobile Number</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="Enter mobile number">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter email">
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Address</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter address">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Town/City</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Town/City">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">State</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Select state">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Postcode</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="Enter postcode">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Country/Region</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Select Country/Region">
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- ----====----====----====----====----==== SIDE BAR ====----====----====----====----====---- -->

    <div class="col-12 pb-3 col-lg-4">
      <div class="sidebar">
        <div class="d-flex justify-content-center">
          <div class="side-heading d-flex px-3 justify-content-between">
            <div>
              <h4>{{ CartImgs.length | number }} Items</h4>
            </div>
            <div class="d-flex align-items-center">
              Edit
            </div>
          </div>
        </div>

        <div class="row">
          <div class=" col-12 col-md-6 col-lg-12 d-flex my-3 px-3 justify-content-center"
            *ngFor="let cart of CartImgs; index as i">
            <div class="col-3 px-2">
              <div class="product-image ratio"  [style.background-image]="'url( ' + cart.img + ')'"></div>
            </div>
            <div class="ps-2 col-9">
              <div>{{ cart.description }}</div>
              <div><strong>&#8377; {{ cart.price | number }}</strong></div>
              <div>Qty: <strong> 1</strong></div>
            </div>
          </div>

          <div class=" subtotal pt-3 px-3">
            <div class="d-flex justify-content-between">
              <div>Subtotal</div>
              <div>sub price</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Delivery</div>
              <div>Free</div>
            </div>
            <div class="d-flex justify-content-between">
              <div><strong>Total Pay</strong></div>
              <div><strong>&#8377; {{ totalPrice | number}}</strong></div>
            </div>
          </div>

          <div class="pt-3 px-3 px-md-0">
            <!-- <a [routerLink]="['/payment']"><app-main-button [MctBtnText]="'Payment'"></app-main-button></a> -->
            <a [routerLink]="['/payment']"><app-checkout-btn [MctBtnText]="'Payment'"></app-checkout-btn></a>
          </div>

        </div>

      </div>
    </div>
  </div>