import { Component } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from 'src/app/components/bonus-ui/base/modal/modal.component';
import { SharedVariableService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'app-order-listing',
  templateUrl: './order-listing.component.html',
  styleUrls: ['./order-listing.component.scss']
})
export class OrderListingComponent {
  constructor(public ModelService: SharedVariableService) { }


  CartImgs = [
    {
      OrderId: 10254854455, name: 'Peter', PayMode: 'COD', SellingPrice: '1726', Color: '#7c818d', status: 3, OrderDate: '25 Sep, 2023 ',
      TrackerOBJ: [
        { Position: true, Progress: true, Status: 'Order Placed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '07:21 AM', },
        { Position: true, Progress: true, Status: 'Payment Confirmed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '08:45 AM', },
        { Position: true, Progress: true, Status: 'In-Progress', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '24-09-2023', Time: '02:26 AM', },
        { Position: true, Progress: true, Status: 'Shipped', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '28-09-2023', Time: '04:15 AM', },
        { Position: true, Progress: false, Status: 'Cancel', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
      ]
    },
    {
      OrderId: 14554254854, name: 'John', PayMode: 'PAID', SellingPrice: '782', Color: '#687666', status: 1, OrderDate: '22 Sep, 2023 ',
      TrackerOBJ: [
        { Position: true, Progress: true, Status: 'Order Placed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '07:21 AM', },
        { Position: true, Progress: true, Status: 'Payment Confirmed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '08:45 AM', },
        { Position: true, Progress: false, Status: 'In-Progress', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '24-09-2023', Time: '02:26 AM', },
        { Position: false, Progress: false, Status: 'Shipped', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '28-09-2023', Time: '04:15 AM', },
        { Position: false, Progress: false, Status: 'Delivered', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
        { Position: false, Progress: false, Status: 'Completed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
      ]
    },
    {
      OrderId: 19845645955, name: 'Sam', PayMode: 'PAID', SellingPrice: '1198', Color: '#854838', status: 1, OrderDate: '22 Sep, 2023 ',
      TrackerOBJ: [
        { Position: true, Progress: true, Status: 'Order Placed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '07:21 AM', },
        { Position: true, Progress: true, Status: 'Payment Confirmed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '08:45 AM', },
        { Position: true, Progress: false, Status: 'In-Progress', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '24-09-2023', Time: '02:26 AM', },
        { Position: false, Progress: false, Status: 'Shipped', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '28-09-2023', Time: '04:15 AM', },
        { Position: false, Progress: false, Status: 'Delivered', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
        { Position: false, Progress: false, Status: 'Completed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
      ]
    },
    {
      OrderId: 18554546687, name: 'Vivek', PayMode: 'PAID', SellingPrice: '1989', Color: '#57718c', status: 2, OrderDate: '21 Sep, 2023 ',
      TrackerOBJ: [
        { Position: true, Progress: true, Status: 'Order Placed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '07:21 AM', },
        { Position: true, Progress: true, Status: 'Payment Confirmed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '23-09-2023', Time: '08:45 AM', },
        { Position: true, Progress: true, Status: 'In-Progress', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '24-09-2023', Time: '02:26 AM', },
        { Position: true, Progress: true, Status: 'Shipped', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '28-09-2023', Time: '04:15 AM', },
        { Position: true, Progress: true, Status: 'Delivered', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
        { Position: true, Progress: true, Status: 'Completed', Msg: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, recusandae!', Date: '29-09-2023', Time: '12:21 AM', },
      ]
    },
  ];
  OrderImgs = [
    { OrderId: 10254854455, name: 'Trainers Shoes', img: 'https://minimog-4437.kxcdn.com/wp-content/uploads/2021/12/product_fashion_34_a_1-450x600.jpg', RealPrice: '1982', SellingPrice: '1726', Color: '#7c818d', status: 3, OrderDate: '25 Sep, 2023 ' }
  ];

  // hello(TrackerModel){
  //   this.ModelService.openCustomModal(TrackerModel)
  // }


}

