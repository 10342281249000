import { Component, Input } from '@angular/core';
import { SharedVariableService } from '../../services/shared.service';

@Component({
  selector: 'app-order-detail-btn',
  templateUrl: './order-detail-btn.component.html',
  styleUrls: ['./order-detail-btn.component.scss']
})
export class OrderDetailBtnComponent {
  @Input() BtnText = 'View Details'
  @Input() TrackerModel
  constructor(private sharedService: SharedVariableService){}

  callFun(TrackerModel){
    // this.sharedService.openCustomModal(TrackerModel)
    this.sharedService.openVerticallyCentered(TrackerModel)
  }
}
