import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigatin-bar',
  templateUrl: './navigatin-bar.component.html',
  styleUrls: ['./navigatin-bar.component.scss'],
})
export class NavigatinBarComponent implements OnInit {
  ngOnInit(): void {
    if(window.innerWidth > 821){
      this.nav_gap = false
    }else{
      this.nav_gap = true
    }
  } 
  
  nav_gap:boolean;

  @HostListener( 'window:resize',['$event']) responsive(event:any){
    if(window.innerWidth > 821){
      this.nav_gap = false
    }else{
      this.nav_gap = true
    }
  } 
}
