import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID)private platformId: any){}

  QtyCount = 1;

  QtyMinus() {
    if (this.QtyCount == 0) {
      this.QtyCount = 1
    } else if (this.QtyCount == 1) {
      this.QtyCount = 1
    } else {
      this.QtyCount--
    }
  }
  QtyPlus() { this.QtyCount++ }



  ProductColors1: string = 'black'
  ProductColors2: string = '#f74444'
  ProductColors3: string = 'green'
  ProductColors4: string = '#39ff1d'
  ProductColors5: string = '#9d5dff'



  // images = [
  //   "https://www.mangaldeep.co.in/image/cache/data/a-line-lehenga-choli-resham-silk-in-red-55167-800x1100.jpg",
  // ]
  product =
    {
      name: 'Suave Dori Work Bridal Lehenga Choli',
      brand: 'Mangaldeep',
      price: 9600,
      available: 1,
      product_code: '50276',
      category: 'Lehenga Choli',
      catalog_no: '7596',
      weight: '1.5KG',
      fabric: 'Velvet',
      work: 'Embroidered, Stone Work, Dori Work',
      color: 'Maroon',
      occasion: 'Wedding, Bridal, Engagement, Reception',
      img: [
        "https://www.mangaldeep.co.in/image/cache/data/suave-dori-work-bridal-lehenga-choli-50276-800x1100.jpg",
        "https://www.mangaldeep.co.in/image/cache/data/suave-dori-work-bridal-lehenga-choli-50276-0-800x1100.jpg",
        "https://www.mangaldeep.co.in/image/cache/data/suave-dori-work-bridal-lehenga-choli-50276-1-800x1100.jpg",
      ],
      description: 'You will be the center of attention in this attire. Be the sunshine of everyone’s eyes dressed in this beautiful maroon velvet lehenga choli. The lovely dori work, embroidered and stone work throughout the attire is awe inspiring. Comes with matching choli and dupatta. (Slight variation in color, fabric & work is possible. Model images are only representative.)',
      disclaimer: [
        "The shades may vary slightly from the colors displayed on your screen.",
        "The first wash of the garment should always be Dry-Cleaned.",
        "Designer Blouse shown in the Image may not be the part of standard product.Extra material may have been used for modeling purpose.",
        "The Products Shown on the website are not the part of Display at our Store",
        "There might be slight color variation due to lightings & flash while photo shoot.",
        "The bright shade seen is the best closer view of fabric's color.",
        "The Products Shown on the website are not the part of Display at our Store.",
      ],
    };

  similar = [
    {
      name: 'Silk Zari Lehenga Choli In Pink',
      offer: '50% OFF',
      price: '13670',
      mrp: '15675',
      wish:false,
      img: 'https://www.mangaldeep.co.in/image/cache/data/a-line-lehenga-choli-resham-silk-in-red-55167-800x1100.jpg',
    },
    {
      name: 'Embroidered Silk Designer Lehenga Choli In Pink',
      offer: '45% OFF',
      price: '9880',
      mrp: '12885',
      wish:true,
      img: 'https://www.mangaldeep.co.in/image/cache/data/embroidered-silk-designer-lehenga-choli-in-pink-62467-800x1100.jpg',
    },
    {
      name: 'Magnetic Velvet Patch Border Lehenga Choli',
      offer: '45% OFF',
      price: '9540',
      mrp: '11250',
      wish:false,
      img: 'https://www.mangaldeep.co.in/image/cache/data/magnetic-velvet-patch-border-lehenga-choli-49622-800x1100.jpg',
    },
    {
      name: 'Princely Lehenga Choli For Party',
      offer: '45% OFF',
      price: '13560',
      mrp: '16360',
      wish:false,
      img: 'https://www.mangaldeep.co.in/image/cache/data/princely-lehenga-choli-for-party-62285-800x1100.jpg',
    },
    {
      name: 'Girlish Handwork Velvet Lehenga Choli',
      offer: '45% OFF',
      price: '8790',
      mrp: '11250',
      wish:true,
      img: 'https://www.mangaldeep.co.in/image/cache/data/girlish-handwork-velvet-lehenga-choli-49624-800x1100.jpg',
    },
    {
      name: 'Pink Patch Border Lehenga Choli',
      offer: '45% OFF',
      price: '8790',
      mrp: '11250',
      wish:false,
      img: 'https://www.mangaldeep.co.in/image/cache/data/pink-patch-border-lehenga-choli-49627-800x1100.jpg',
    },
  ];

  ImageColCLass = '';
  ColCLass = 'col-2';
  ActiveClass = 3
  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      
      if (window.innerWidth > 769) {
        this.ColCLass = "col-3"
      } else {
        this.ColCLass = "col-6"
      }
      window.addEventListener('resize', this.onResize.bind(this));

    }
  }

  
  onResize(event: any) {
    if (window.innerWidth > 821) {
      this.ColCLass = "col-3"
    } else {
      this.ColCLass = "col-6"
    }
  }
  nav_gap: boolean;

  



  slideConfig = {   // Start configration of slick slider -------- ====
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
    fade: true,
    autoplay: false,
    draggable: true,
    asNavFor: ".products-thumb",
    dots: true,
    responsive: [  // Set responsive of slick slider in configration as a array -------- ====
      {  // Create responsive object-1
        breakpoint: 769,
        settings: {
          'slidesToShow': 1,
          'slidesToScroll': 1,
          autoplay: true,
          fade: false,
        },

      },  // End responsive object-1
    ]  // responsive array finish
  };
  thumbnailsSlider = {   // Start configration of slick slider -------- ====
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: 'linear',
    autoplay: false,
    arrows: false,
    // centerMode:true,
    draggable: true,
    focusOnSelect: true,
    asNavFor: ".products",
    vertical: true,
    verticalSwiping: true,
  };


}

