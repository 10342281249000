<div class="container py-3">
    <div class="row">
        <div class="d-flex justify-content-between"><h4 class="mb-3">Wedding Collection</h4></div>
        <div class="col-6 col-lg-2" *ngFor="let collection of collections">
            <div class="card text-left position-relative image-card m-0">
                <img class="card-img-top" src="{{ collection.img }}" alt="">
                <div class="position-absolute" style="top: 85%;right:10%">
                    <div class="d-flex justify-content-end align-items-center px-3 pb-4">
                        <!-- <div class="col">
                    </div> -->
                        <div class="col-2 me-1">
                            <a routerLink="/product-listing" class="arrow-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" fill="none"
                                    viewBox="0 0 14 13">
                                    <path fill="currentColor"
                                        d="M6.78594.789062c.16406-.145833.31901-.145833.46484 0L12.9656 6.53125c.1641.14583.1641.29167 0 .4375L7.25078 12.7109c-.14583.1459-.30078.1459-.46484 0l-.54688-.5468c-.05469-.0547-.08203-.1276-.08203-.2188 0-.0911.02734-.1732.08203-.2461l4.23824-4.23826H1.15312c-.218745 0-.32812-.10938-.32812-.32813v-.76562c0-.21875.109375-.32813.32812-.32813h9.32418L6.23906 1.80078c-.14583-.16406-.14583-.31901 0-.46484l.54688-.546878z">
                                    </path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <h6 class="mt-2 text-center px-2 mb-4">{{ collection.name }}</h6>
        </div>
    </div>
</div>