<div class="d-flex main-box justify-content-center">

    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="check-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <h1 class="m-0">Thank You</h1>
        </div>
        <div class="mt-3">
            <p class="text-center m-0">Thanks So Much for Your Order!</p>
            <p class="text-center m-0">I Hope You Enjoy Your New Purchase!</p>
        </div>
        <div class="d-flex mt-4 justify-content-center">
            <a [routerLink]="['/my-account/my-orders']"><div class="d-flex track-order-btn">Track Your Order</div></a>
        </div>
    </div>

</div>
