<figure class="img-box position-relative mb-1">
  <img src="{{ image }}" alt="" class="h-100 w-100" style="object-fit: cover;">
  <!-- <a routerLink="/product-details-filter" class="product-link"></a> -->
  <a [routerLink]=url class="product-link overlay-imagge">
     <img src="{{ image2 }}" *ngIf="image2"
      alt="" class="h-100 w-100" style="object-fit: cover;">
  </a>
  <div class="position-absolute top-0 w-100  p-2">
    <span class="badge badge-danger text-white float-start" *ngIf="badge">{{ badge | uppercase }}</span>
  </div>
  <div class="position-absolute add-cart-btn">
    <div class="add-button mx-2">
      <app-main-button [MctBtnText]="'Add To Cart'"></app-main-button>
    </div>
  </div>
  <div class="position-absolute add-wish">
    <app-wish-icon [wish]="wish"></app-wish-icon>
  </div>
</figure>

<div class="product-details mb-4">
  <div>
    <h6 class="text-center m-0 pt-1">{{ name | uppercase }}</h6>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <div class="product-rate text-danger" *ngIf="price">&#8377; {{ price | number }}</div>
    <div class="ms-2 mrp-price" *ngIf="mrp"><s> &#8377; {{ mrp | number }}</s></div>
  </div>
</div>