<div class="container py-3 mt-4">
    <div class="">
        <div class="text-center mb-5">
            <!-- <h6 class="text-danger">{{ 'Festive sale' | uppercase }}</h6>  -->
            <h4>{{ 'new arrivals' | uppercase }}</h4>
        </div>
        <div class="d-flex row">
            <div class="col-6 col-lg-2" *ngFor="let item of new_arrivals">
                <app-basic-product-card [image]="item.img" [wish]="item.wish" [name]="item.name" [price]="item.price" [badge]="'new'"></app-basic-product-card>
            </div>
        </div>
    </div>
</div>