import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-order-timeline',
  templateUrl: './order-status-timeline.component.html',
  styleUrls: ['./order-status-timeline.component.scss']
})
export class OrderStatusTimelineComponent  {
track = 1
TimeLineFill='34.5%';
}
