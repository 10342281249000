import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private metaService: Meta){
    this.metaService.addTags([
      {'name': 'title', 'content':'Ethnic Star'},
      {'name' : 'description', 'content': 'Elevate your style with our chic and sustainable clothing. Discover timeless fashion that reflects your unique personality. Shop now!'},
      {'name': 'keywords', 'content':'fashion, sustainable clothing, chic style, timeless apparel, eco-friendly fashion, unique wardrobe, trendy outfits, online shopping, clothing brand'},
      {'name': 'language', 'content':'English'},
      {'httpEquiv': 'Content-Type', 'content':'text/html; charset=utf-8'},
    ])
  }
}
