import { Component } from '@angular/core';
import { SharedVariableService } from '../../services/shared.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {
  constructor(public sharedService: SharedVariableService) { }
  Menu(){
    this.sharedService.Menu = false;
  }
}
