import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-detail-with-filter',
  templateUrl: './product-detail-with-filter.component.html',
  styleUrls: ['./product-detail-with-filter.component.scss']
})
export class ProductDetailWithFilterComponent implements OnInit {



  QtyCount = 1;

  QtyMinus() {
    if (this.QtyCount == 0) {
      this.QtyCount = 1
    } else if (this.QtyCount == 1) {
      this.QtyCount = 1
    } else {
      this.QtyCount--
    }
  }
  QtyPlus() { this.QtyCount++ }



  ProductColors1: string = 'black'
  ProductColors2: string = '#f74444'
  ProductColors3: string = 'green'
  ProductColors4: string = '#39ff1d'
  ProductColors5: string = '#9d5dff'



  product =
    {
      name: 'Navy Blue Thread Embroidered Indowestern Set',
      brand: 'Ethnic Star',
      price: 9949,
      available: 1,
      product_code: 'MIW52062',
      category: 'Indo Western',
      catalog_no: '7596',
      weight: '0.7KG',
      fabric: 'Silk',
      work: 'Embroidered, Stone Work, Dupion Silk',
      color: 'Navy Blue , Off White',
      occasion: 'Wedding, Engagement, Reception',
      img: [
        "https://ethnicstar.com/cdn/shop/files/52062_1.jpg?crop=center&height=1100&v=1701932130&width=800",
        "https://ethnicstar.com/cdn/shop/files/52062_3.jpg?crop=center&height=1100&v=1701932130&width=800",
        "https://ethnicstar.com/cdn/shop/files/52062_2.jpg?crop=center&height=1100&v=1701932131&width=800",
      ],
      description: 'You will be the center of attention in this attire. (Slight variation in color, fabric & work is possible. Model images are only representative.)',
      disclaimer: [
        "The shades may vary slightly from the colors displayed on your screen.",
        "The first wash of the garment should always be Dry-Cleaned.",
        "The Products Shown on the website are not the part of Display at our Store",
        "There might be slight color variation due to lightings & flash while photo shoot.",
        "The bright shade seen is the best closer view of fabric's color.",
        "The Products Shown on the website are not the part of Display at our Store.",
      ],
    };


    similar = [
      { name: 'Indo Western',description:'Art Silk Black Embroidered Indowestern Set', img: 'https://ethnicstar.com/cdn/shop/files/52063_1.jpg?crop=center&height=1100&v=1701932274&width=800', mrp: '10846', price: '9949', Color: '#2d3142', Qty:1, wish:false,},
      { name: 'Jodhpuri Suit',description:'Misty Rose Pink Embroidered Mens Jodhpuri Suit', img: 'https://ethnicstar.com/cdn/shop/files/MJS50728_1.jpg?crop=center&height=1100&v=1698312481&width=800', mrp: '18595', price: '17995', Color: '#c1a8a1', Qty:2, wish:false,},
      { name: 'Safa & Dupatta',description:'Heavy Groom Wear Safa, Dupatta And Mojari', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrown50974_1.jpg?crop=center&height=1100&v=1699184478&width=800', mrp: '7500', price: '6700', Color: '#8a0018', Qty:1, wish:false,},
      { name: 'Indo Western',description:'Traditional Purple Color Indowestern In Art Silk', img: 'https://ethnicstar.com/cdn/shop/files/52061_1.jpg?crop=center&height=1100&v=1701931953&width=800', mrp: '10846', price: '9949', Color: '#41223a', Qty:1, wish:false,},
      { name: 'Mojari',description:'Wedding Wear Leather Fabric Mojari', img: 'https://ethnicstar.com/cdn/shop/files/batch_ChoclateBrownMJS51312.jpg?crop=center&height=1100&v=1699182156&width=800', mrp: '1499', price: '1399', Color: '#7b4424', Qty:1, wish:false,},
      { name: 'Indo Western',description:'Designer Black Jacket Style Indowestern In Silk', img: 'https://ethnicstar.com/cdn/shop/files/50761_1.jpg?crop=center&height=1100&v=1699854707&width=800', mrp: '14599', price: '13999', Color: '#7b4424', Qty:1, wish:false,},
    ];

  ImageColCLass = '';
  ColCLass = 'col-2';
  ActiveClass = 3
  sizeValue = "valSize1"
  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      
      if (window.innerWidth > 769) {
        this.ColCLass = "col-3"
      } else {
        this.ColCLass = "col-6"
      }
      window.addEventListener('resize', this.onResize.bind(this));

    }
  }

  
  onResize(event: any) {
    if (window.innerWidth > 821) {
      this.ColCLass = "col-3"
    } else {
      this.ColCLass = "col-6"
    }
  }
  nav_gap: boolean;



  slideConfig = {   // Start configration of slick slider -------- ====
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
    fade: true,
    autoplay: false,
    draggable: true,
    asNavFor: ".products-thumb",
    dots: true,
    responsive: [  // Set responsive of slick slider in configration as a array -------- ====
      {  // Create responsive object-1
        breakpoint: 769,
        settings: {
          'slidesToShow': 1,
          'slidesToScroll': 1,
          autoplay: true,
          fade: false,
        },

      },  // End responsive object-1
    ]  // responsive array finish
  };
  thumbnailsSlider = {   // Start configration of slick slider -------- ====
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: 'linear',
    autoplay: false,
    arrows: false,
    // centerMode:true,
    draggable: true,
    focusOnSelect: true,
    asNavFor: ".products",
    vertical: true,
    verticalSwiping: true,
  };


  closeResult: string;
  constructor(config: NgbModalConfig, private modalService: NgbModal, @Inject(PLATFORM_ID) private platformId: any) {
  	// customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    config.size = 'xl';
    config.centered = true;
  }

  open(Size_content) {
    this.modalService.open(Size_content);
  }

}
